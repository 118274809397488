import { PermissionCheckerService } from '@abp/auth/permission-checker.service';
import { AppSessionService } from '@shared/common/session/app-session.service';

import { Injectable } from '@angular/core';
import { AppMenu } from './app-menu';
import { AppMenuItem } from './app-menu-item';

@Injectable()
export class AppNavigationService {

    constructor(
        private _permissionCheckerService: PermissionCheckerService,
        private _appSessionService: AppSessionService
    ) {
    }

    getMenu(): AppMenu {
        return new AppMenu('MainMenu', 'MainMenu', [
            new AppMenuItem('Dashboard', 'Pages.Administration.Host.Dashboard', 'flaticon-line-graph', '/app/admin/hostDashboard'),
            new AppMenuItem('Dashboard', 'Pages.Tenant.Dashboard', 'flaticon-line-graph', '/app/main/dashboard'),


            
            new AppMenuItem('Editions', 'Pages.Editions', 'flaticon-app', '/app/admin/editions'),


            new AppMenuItem('Referral and Assessment', 'Pages.Referrals', 'flaticon-avatar', '', [
                new AppMenuItem('Referral', 'Pages.Referrals', 'fa fa-user-friends', '/app/main/referrals/referrals'),
                new AppMenuItem('Biopsychosocial Assessment', 'Pages.IntakeClients', 'fa fa-users', '/app/main/intakeClients/intakeClients'),
                new AppMenuItem('Psychiatric Evaluation', 'Pages.PsycEvalses', 'fa fa-tasks', '/app/main/psycEval/psycEvalses'),
                new AppMenuItem('Health Home Assessment', 'Pages.HealthHomes', 'la la-university', '/app/main/healthHomes/healthHomes'),
                new AppMenuItem('Daily Living Activities', 'Pages.DLAs', 'fa fa-medkit', '/app/main/dla/dlAs'),
            ]),

            new AppMenuItem('Client Profile', 'Pages.Clients', 'flaticon-profile-1', '/app/main/clients/clients'),

            new AppMenuItem('Plans', '', 'flaticon-list', '', [
                new AppMenuItem('Service Plan', 'Pages.TreatmentPlans', 'fa fa-cogs', '/app/main/treatmentPlan/treatmentPlans'),
                new AppMenuItem('Discharge Summary', 'Pages.DischargeSummaries', 'fa fa-notes-medical', '/app/main/dischargeSummaries/dischargeSummaries'),
                new AppMenuItem('Transition Continuing Care Plan', 'Pages.TransitionCarePlans', 'fa fa-object-group', '/app/main/transitionCarePlans/transitionCarePlans'),
                new AppMenuItem('PersonalSafetyPlans', 'Pages.PersonalSafetyPlans', 'fa fa-book', '/app/main/personalSafetyPlan/personalSafetyPlans'),
                //new AppMenuItem('Managed Intervention Plan', 'Pages.MIPs', 'la la-cubes', '/app/main/mip/miPs'),
            ]),

            //new AppMenuItem('Transaction', '', 'flaticon-menu-2', '', [
            //    new AppMenuItem('Transactions', 'Pages.Tenant.Dashboard', 'flaticon-line-graph', '/app/main/dashboard'),
            //    //new AppMenuItem('Med Management', 'Pages.MedManagment', 'fas fa-notes-medical', '/app/main/medManagement/medManagement')
            //]),

            new AppMenuItem('Services', '', 'flaticon-menu-2', '', [
                new AppMenuItem('Med Management', 'Pages.MedManagment', 'fas fa-notes-medical', '/app/main/medManagement'),
                new AppMenuItem('Individual Clinic Contact Note', 'Pages.IndividualClinicalNotes', 'fa fa-address-card', '/app/main/individualClinicalNotes/individualClinicalNotes'),
                new AppMenuItem('Group Contact Note', 'Pages.GroupContactNotes', 'fa fa-users', '/app/main/groupContactNotes/groupContactNotes'),
                new AppMenuItem('Rehab Individual Note', 'Pages.RehabNotes', 'fa fa-retweet', '/app/main/rehabNotes/rehabNotes'),
            ]),

            new AppMenuItem('Client Chart Drop Off', 'Pages.Sharepoint', 'fa fa-file', '/app/main/clientChartDropOff'),
            new AppMenuItem('Rehab Progress Notes', 'Pages.RehabProgrssNotes', 'fa fa-book', '/app/progrssnotes/progrssNotes/rehabProgrssNotes'),



            new AppMenuItem('Vitals', 'Pages.Vitals', 'fa fa-heart', '/app/main/vitals/vitals'),

            // new AppMenuItem('ClientNotes', 'Pages.ClientNotes', 'flaticon-more', '/app/main/clientNotes/clientNotes'),

            //new AppMenuItem('StickyNotes', '', 'flaticon-more', '/app/main/stickyNotes/stickyNotes'),


           
            new AppMenuItem('IndividualNotes', 'Pages.IndividualNotes', 'flaticon-more', '/app/main/individualNotes/individualNotes'),
             new AppMenuItem('Administration', '', 'flaticon-interface-8', '', [
                new AppMenuItem('OrganizationUnits', 'Pages.Administration.OrganizationUnits', 'flaticon-map', '/app/admin/organization-units'),
                new AppMenuItem('Roles', 'Pages.Administration.Roles', 'flaticon-suitcase', '/app/admin/roles'),
                new AppMenuItem('Users', 'Pages.Administration.Users', 'flaticon-users', '/app/admin/users'),


                new AppMenuItem('Providers', 'Pages.Administration.Providers', 'flaticon-user-settings', '/app/admin/providers/providers'),
                new AppMenuItem('Programs', 'Pages.Administration.HealthCareServices', 'la la-pencil-square', '/app/admin/healthcareServices/healthCareServices'),
                new AppMenuItem('Referral Points', 'Pages.Administration.ReferralPoints', 'fa fa-user-friends', '/app/admin/referralPoints/referralPoints'),
                new AppMenuItem('Payers', 'Pages.Administration.Payers', 'fa fa-hand-holding-usd', '/app/admin/payers/payers'),
                //  new AppMenuItem('Practice', 'Pages.Administration.Organizations', 'fa fa-diagnoses', '/app/admin/organizations/organizations'),
                new AppMenuItem('Locations', 'Pages.Administration.Locations', 'flaticon-map-location', '/app/admin/locations/locations'),
                //new AppMenuItem('Languages', 'Pages.Administration.Languages', 'flaticon-tabs', '/app/admin/languages'),
                //new AppMenuItem('Audit Logs', 'Pages.Administration.AuditLogs', 'flaticon-folder-1', '/app/admin/auditLogs'),
                //new AppMenuItem('Maintenance', 'Pages.Administration.Host.Maintenance', 'flaticon-lock', '/app/admin/maintenance'),
                //new AppMenuItem('Subscription', 'Pages.Administration.Tenant.SubscriptionManagement', 'flaticon-refresh', '/app/admin/subscription-management'),
                //new AppMenuItem('Visual Settings', 'Pages.Administration.UiCustomization', 'flaticon-medical', '/app/admin/ui-customization'),
                new AppMenuItem('Settings', 'Pages.Administration.Host.Settings', 'flaticon-settings', '/app/admin/hostSettings'),
                new AppMenuItem('Settings', 'Pages.Administration.Tenant.Settings', 'flaticon-settings', '/app/admin/tenantSettings'),
                new AppMenuItem('Extension Template', '', 'flaticon-list', '/app/admin/surveyjseditor'),

                new AppMenuItem('Masters', '', 'fa fa-bezier-curve', '', [
                    new AppMenuItem('Organization types', 'Pages.Administration.OrganizationTypes', 'fa flaticon-map', '/app/admin/organizations/organizationTypes'),
                    new AppMenuItem('Counties', 'Pages.Administration.Counties', 'flaticon-location', '/app/admin/counties/counties'),
                    new AppMenuItem('States', 'Pages.Administration.States', 'fa fa-archway', '/app/admin/states/states'),
                    new AppMenuItem('Countries', 'Pages.Administration.Countries', 'fa fa-building', '/app/admin/country/countries'),
                    new AppMenuItem('Plan types', 'Pages.Administration.Plantypes', 'fa flaticon-presentation-1', '/app/admin/plantypes/plantypes'),
                    new AppMenuItem('Document types', 'Pages.Administration.Documenttypes', 'fa fa-file-signature', '/app/admin/documenttypes/documenttypes'),
                    //new AppMenuItem('Modules', 'Pages.Administration.Modules', 'flaticon-more', '/app/admin/modules/modules'),
                    new AppMenuItem('Frequencies', 'Pages.Administration.Frequencies', 'fa fa-signature', '/app/admin/doseFrequencies/frequencies'),
                    new AppMenuItem('Units', 'Pages.Administration.Doseunits', 'fa fa-pills', '/app/admin/doseunits/doseunits'),
                    new AppMenuItem('Severities', 'Pages.Administration.Severities', 'socicon-spotify', '/app/admin/severities/severities'),
                    new AppMenuItem('Relationships', 'Pages.Administration.Relationships', 'socicon-draugiem', '/app/admin/relationships/relationships'),
                    new AppMenuItem('Specialities', 'Pages.Administration.Specialities', 'fa fa-briefcase-medical', '/app/admin/specialitys/specialities'),
                ])
            ]),


            //,
           // new AppMenuItem('DemoUiComponents', null, 'flaticon-shapes', '/app/admin/demo-ui-components')
        ]);
    }

    checkChildMenuItemPermission(menuItem): boolean {

        for (let i = 0; i < menuItem.items.length; i++) {
            let subMenuItem = menuItem.items[i];

            if (subMenuItem.permissionName && this._permissionCheckerService.isGranted(subMenuItem.permissionName)) {
                return true;
            } else if (subMenuItem.items && subMenuItem.items.length) {
                return this.checkChildMenuItemPermission(subMenuItem);
            }
        }

        return false;
    }

    showMenuItem(menuItem: AppMenuItem): boolean {
        if (menuItem.permissionName === 'Pages.Administration.Tenant.SubscriptionManagement' && this._appSessionService.tenant && !this._appSessionService.tenant.edition) {
            return false;
        }

        let hideMenuItem = false;

        if (menuItem.requiresAuthentication && !this._appSessionService.user) {
            hideMenuItem = true;
        }

        if (menuItem.permissionName && !this._permissionCheckerService.isGranted(menuItem.permissionName)) {
            hideMenuItem = true;
        }

        if (menuItem.hasFeatureDependency() && !menuItem.featureDependencySatisfied()) {
            hideMenuItem = true;
        }

        if (!hideMenuItem && menuItem.items && menuItem.items.length) {
            return this.checkChildMenuItemPermission(menuItem);
        }

        return !hideMenuItem;
    }
}
