import { Component, ViewChild, Injector, Output, EventEmitter } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { finalize } from 'rxjs/operators';
import { RefSourceForTccpsServiceProxy, CreateOrEditRefSourceForTccpDto, CommonServiceProxy, CreateOrEditReferralSourceDto } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
import * as moment from 'moment';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
    selector: 'createOrEditRefSourceForTccpModal',
    templateUrl: './create-or-edit-refSourceForTccp-modal.component.html'
})
export class CreateOrEditRefSourceForTccpModalComponent extends AppComponentBase {

    @ViewChild('createOrEditModal') modal: ModalDirective;
    @ViewChild('refSourceForTccpForm') form;
    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();
    @Output() refSourceForTccpemitter: EventEmitter<any> = new EventEmitter<any>();

    active = false;
    saving = false;
    // _formsubmitted;
    _idx?: number
    _edit: boolean = false;
    refSourceForTccpold: any;
    originalobj: any;

    private sub: any;
    refSourceForTccpId: number;
    TCCPDDLMastersMastersd: any[];
    RefSource: any;
    _formsubmitted = false;

    refSourceForTccp: CreateOrEditRefSourceForTccpDto = new CreateOrEditRefSourceForTccpDto();
    refSourceForTccplist: CreateOrEditRefSourceForTccpDto[] = new Array();

    constructor(
        injector: Injector,
        private _router: Router,
        private route: ActivatedRoute,
        private _commonServiceProxy: CommonServiceProxy,

        private _refSourceForTccpsServiceProxy: RefSourceForTccpsServiceProxy
    ) {
        super(injector);

        this.sub = this.route.params.subscribe(params => {
            this.refSourceForTccpId = +params['refSourceForTccpId'];
        });
    }

    ngOnInit() {

        this.Binddropdown();

    }
    show(refSourceForTccpId?: number): void {
        
        this._formsubmitted = false;
        
        if (refSourceForTccpId == 0) {
            refSourceForTccpId = null;
            this._edit = false;
            this._idx = null;
        }
       // if (!refSourceForTccpId) {
       //     this.refSourceForTccp = new CreateOrEditRefSourceForTccpDto();
       //     this.refSourceForTccp.id = refSourceForTccpId;
            
       //     this.active = true;
       //     this.modal.show();
       // } else {
            this._refSourceForTccpsServiceProxy.getRefSourceForTccpForEdit(refSourceForTccpId).subscribe(result => {
                this.refSourceForTccp = result.refSourceForTccp;
                this.refSourceForTccp.id = Math.floor(Math.random() * (999999 - 100000)) + 100000;
                this.active = true;
                //this.selectedValue = null;
                this.originalobj = Object.assign({}, this.refSourceForTccp);
                this.refSourceForTccpold = Object.assign({}, this.refSourceForTccp);
                this.modal.show();
            });
        //}
    }

    public Binddropdown() {

        this._commonServiceProxy.getTCCPreferralsource().subscribe(result => {
            
            this.TCCPDDLMastersMastersd = result;
            this.RefSource = this.TCCPDDLMastersMastersd.filter(s => s.tccpddlMaster.tccpName == 'Referral Source');
        });
    }

    save() {
        
        let refSourceForTccps = new CreateOrEditRefSourceForTccpDto();
        this._formsubmitted = true;
        this.saving = true;

        if (this.form.valid === false)
            return false;
        else
            this.refSourceForTccpold = null;



        if (this._edit)
            this.refSourceForTccplist.splice(this._idx, 1);
        
        refSourceForTccps.init(this.refSourceForTccp);
        this.refSourceForTccplist.push(refSourceForTccps);
        this.refSourceForTccpemitter.emit(this.refSourceForTccplist);
        this._edit = false;
        this._idx = null;
        this.modal.hide();
        //this._refSourceForTccpsServiceProxy.createOrEdit(this.refSourceForTccp)
        // .pipe(finalize(() => { this.saving = false;}))
        // .subscribe(() => {
        //    this.notify.info(this.l('SavedSuccessfully'));
        //    this.close();
        //    this.modalSave.emit(null);
        // });
    }

    assigndata(refSourceForTccplst: CreateOrEditRefSourceForTccpDto[]) {
        
        this.refSourceForTccplist = refSourceForTccplst;
    }

    editrefSourceForTccp(result?: CreateOrEditRefSourceForTccpDto, idx?: number): void {
        
        this._idx = idx;
        this._edit = true;
        this.refSourceForTccpold = null;
        this.originalobj = Object.assign({}, result);
        this.refSourceForTccpold = Object.assign({}, result);
        this.refSourceForTccp = this.originalobj;
        //this.refSourceForTccp.id = idx;
        this.active = true;
        this.modal.show();
    }

    close(): void {
        
      
        if (JSON.stringify(this.refSourceForTccp).replace(/\:null/gi, "\:\"\"").toLowerCase() == JSON.stringify(this.refSourceForTccpold).replace(/\:null/gi, "\:\"\"").toLowerCase()) {
            //if (JSON.stringify(this.treatmentMode).toLowerCase() === JSON.stringify(this.originalobj).toLowerCase()) {
            //if (this.treatmentMode === this.originalobj) {
            this.oldObj();
            //this._edit = false;
            this.active = false;
            this.modal.hide();
        }
        else {
            this.message.confirm('Are you sure you want to discard the changes?', ' ',
                (isConfirmed) => {
                    if (isConfirmed) {
                        this.oldObj();
                    }
                }
            );
        }
    }

    //    this.active = false;
    //    this.modal.hide();
    //}



    oldObj() {
        
        if (this.refSourceForTccpold) {
            let symptom = new (CreateOrEditRefSourceForTccpDto);

            if (this._edit) {
                this.refSourceForTccplist.splice(this._idx, 1);
                symptom.init(this.refSourceForTccpold);
                this.refSourceForTccplist.push(symptom);
                this.modalSave.emit(this.refSourceForTccplist);
            }
        }
        this._idx = null;
        this._edit = false;
        this.refSourceForTccpold = null;
        this.active = false;
        this.modal.hide();
    }

    onChange(event) {
        
        this.refSourceForTccp.refSourceName = event.currentTarget.selectedOptions[0].innerText;
    }
}
