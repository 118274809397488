import { Component, ViewChild, Injector, Output, EventEmitter, HostListener, OnInit, ElementRef } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ModalDirective } from 'ngx-bootstrap';
import { IntakeClientsServiceProxy, ClientsServiceProxy, CreateOrEditIntakeClientDto, CommonServiceProxy, ClientSignatureDTO, ClientSignatureServiceProxy } from '@shared/service-proxies/service-proxies';
import { Router, ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { ClientService } from '@app/main/clients/clients/Clientservice';
import { Intakeservice } from '@app/main/intake/intakeClients/Intakeservice';
import { CreateOrEditIntakeClientModalComponent } from '@app/main/intake/intakeClients/create-or-edit-intakeClient-modal.component';

@Component({
    selector: 'clientSignatureModal',
    templateUrl: './clientsignature-modal.component.html'
})

export class clientSignatureModalComponent extends AppComponentBase implements OnInit {


    @ViewChild('clientSignatureModal') modal: ModalDirective;
    @ViewChild('clientSignature') form;

    @ViewChild('sigPad') sigPad;
    //  @ViewChild(' createOrEditIntakeClientModal') createOrEditIntakeClientModal: CreateOrEditIntakeClientModalComponent;
    // blockedDocument: boolean = false;

    clientSignature: ClientSignatureDTO = new ClientSignatureDTO();
    intakeClientSignature: CreateOrEditIntakeClientDto = new CreateOrEditIntakeClientDto();
    intakeClientSignatureList: CreateOrEditIntakeClientDto[] = new Array();


    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();
    @Output() clientsignaturesave: EventEmitter<any> = new EventEmitter<any>();

    sigPadElement;
    context;
    isDrawing = false;
    img;

    ClientName: string;
    ClientId: number;
    block: any;
    showOnSave = false;

    _required = true;
  

    constructor(
        injector: Injector,
        elementRef: ElementRef,
      
    ) { 
        super(injector);
        
    }

    ngOnInit() {

        this.sigPadElement = this.sigPad.nativeElement;
        this.context = this.sigPadElement.getContext('2d');
        this.context.strokeStyle = '#000000';
    }

    //checkCanvasBlank() {
    //   
    //    var canvas = <HTMLCanvasElement>document.getElementById('editor');
    //    var blankCanvas = <HTMLCanvasElement>document.getElementById('blank');
    //    var savebutton = <HTMLCanvasElement>document.getElementById('save');
    //    var ctx = canvas.getContext('2d');

    //    canvas.addEventListener('mousemove', function (e) {
    //        ctx.lineTo(e.pageX, e.pageY);
    //        ctx.stroke();
    //    });
       
    //    savebutton.addEventListener('click', function () {
    //        if (canvas.toDataURL() == blankCanvas.toDataURL()) {
    //            alert('It is blank');
    //           return true;
    //        }
    //        else {
    //            alert('Save it!');
    //           return false;
    //        }
    //    });
    //}

    okSignaturePanel() {
        
        //this.checkCanvasBlank();
        this._required = true;
        if (this.img != null) {
            this.img = this.sigPadElement.toDataURL("image/png");
            this.intakeClientSignature.clientSignature = this.img;
            let sign = new CreateOrEditIntakeClientDto();
            sign.init(this.intakeClientSignature);
            this.intakeClientSignatureList.push(sign)
            this.clientsignaturesave.emit(this.intakeClientSignatureList);
            //this.createOrEditIntakeClientModal.getSignature(this.intakeClientSignature);
            this.modal.hide();
        }
    }

    @HostListener('document:mouseup', ['$event'])
    onMouseUp(e) {
        this.isDrawing = false;
        
    }
    onMouseDown(e) {        
        this.isDrawing = true;
        const coords = this.relativeCoords(e);
        this.context.moveTo(coords.x, coords.y);
        this.img = this.sigPadElement.toDataURL("image/png");
    }
    onMouseMove(e) {       
        if (this.isDrawing) {           
            const coords = this.relativeCoords(e);
            this.context.lineTo(coords.x, coords.y);
            this.context.stroke();
            this.img = this.sigPadElement.toDataURL("image/png");
        }
    }

    private relativeCoords(event) {
        const bounds = event.target.getBoundingClientRect();
        const x = event.clientX - bounds.left;
        const y = event.clientY - bounds.top;
        return { x: x, y: y };
    }

    clearSignature() {
        this.context.clearRect(0, 0, this.sigPadElement.width, this.sigPadElement.height);
        this.context.beginPath();
        this.isDrawing = false;
        this.img = null;
    }

    saveSignature() {
       
        this.img = this.sigPadElement.toDataURL("image/png");
        this.clientSignature.signature = this.img;
        this.intakeClientSignature.clientSignature = this.img;
        //console.log(this.img);
    }


    crosscancel() {
        this.modal.hide();
    }

    closeSignaturePanel() {
        this._required = false;
        this.message.confirm('Do you want to skip this step?', ' ', 
            (isConfirmed) => {
                if (isConfirmed) {
                                      
                    
                        this.img = this.sigPadElement.toDataURL("image/png");
                        this.intakeClientSignature.clientSignature = this.img;
                        let sign = new CreateOrEditIntakeClientDto();
                        sign.init(this.intakeClientSignature);
                        this.intakeClientSignatureList.push(sign)
                        this.clientsignaturesave.emit(this.intakeClientSignatureList);
                        //this.createOrEditIntakeClientModal.getSignature(this.intakeClientSignature);
                        this.modal.hide();
                    
                }
                //else {
                //    this.block = false;
                //    this.isDrawing = false;
                //    this.img = null;
                //    this.clientsignaturesave.emit(this.block);
                //    this.modal.hide();

                //}
            }
        );




        //this.block = false;
        //this.isDrawing = false;
        //this.img = null;
        //this.clientsignaturesave.emit(this.block);
        //this.modal.hide();

       

    }


    show(_clientname: string, _clientid?: number, signatureId?: number): void {
       
        this.clearSignature();
        this.ClientName = _clientname;
        this.ClientId = _clientid;
        this._required = false;
        this.modal.show();
    }

}
