import { Component, ViewChild, Injector, Output, EventEmitter } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ModalDirective } from 'ngx-bootstrap';
import { CreateOrEditBehavioralDiagnosesDto, ClientsServiceProxy, CreateOrEditClientDto, CommonServiceProxy, CommonComponentServiceProxy } from '@shared/service-proxies/service-proxies';
import { Router, ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'commonDiagnosisModal',
    templateUrl: './common-diagnosis-modal.component.html'
})

export class CommonDiagnosisModalComponent extends AppComponentBase {

    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();
    @Output() CommonDiagnosisModalemitter: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('commonDiagnosisModal') modal: ModalDirective;
    Client: CreateOrEditClientDto = new CreateOrEditClientDto();
    diagnosislist: CreateOrEditBehavioralDiagnosesDto[] = new Array();


    clientId: number;
   

    private sub: any;

    constructor(
        injector: Injector,
        private _clientsServiceProxy: ClientsServiceProxy,
        private _commonServiceProxy: CommonServiceProxy,
        private _commonCompServiceProxy: CommonComponentServiceProxy,
        private route: ActivatedRoute
    ) {
        super(injector);
        
        
    }

    show(clientId?: number): void {

        //if (this.diagnosislist[i].type == 1) {
        //    this.diagnosislist[i].typeName ='Medical diagnosis'
        //}
        //else if (this.diagnosislist[i].type == 2) {
        //    this.diagnosislist[i].typeName = 'Behavioral diagnosis'
        //}

        //else (this.diagnosislist[i].type == 3)
        //{
        //    this.diagnosislist[i].typeName = 'Additional diagnosis'
        //}
        

        if (clientId) {
            // this._clientsServiceProxy.getClientForEdit(clientId)
            this._commonCompServiceProxy.getdiagnosislist(clientId) 
            .pipe(finalize(() => { }))
            .subscribe(result => {
               
                //this.Client = result.client;
                this.diagnosislist = result;
                
                this.CommonDiagnosisModalemitter.emit(this.diagnosislist);
                });
        }
    }   
}
