import { Component, ViewChild, Injector, Output, EventEmitter } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ModalDirective } from 'ngx-bootstrap';
import { CreateOrEditClientproviderDto, ClientsServiceProxy, CreateOrEditClientDto, CommonServiceProxy, CommonComponentServiceProxy } from '@shared/service-proxies/service-proxies';
import { Router, ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'commonProviderModal',
    templateUrl: './common-provider-modal.component.html',

})

export class CommonProviderModalComponent extends AppComponentBase {

    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();
    @Output() CommonProviderModalemitter: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('commonProviderModal') modal: ModalDirective;
    Client: CreateOrEditClientDto = new CreateOrEditClientDto();
    clientproviderlist: CreateOrEditClientproviderDto[] = new Array();


    clientId: number;
    TreatmentSite: any[];
    ProviderType: any[];

    private sub: any;

    constructor(
        injector: Injector,
        private _clientsServiceProxy: ClientsServiceProxy,
        private _commonServiceProxy: CommonServiceProxy,
        private _commonCompServiceProxy: CommonComponentServiceProxy,
        private route: ActivatedRoute
    ) {
        super(injector);


    }

    ngOnInit() {
          
        this._commonServiceProxy.getClientProvider().subscribe(result => {
            this.ProviderType = result;
        });

        this._commonServiceProxy.getTreatmentsiteall().subscribe(result => {

            this.TreatmentSite = result;
        });


    }

    show(clientId?: number): void {

        if (clientId) {

            
            this._commonCompServiceProxy.getProviderlist(clientId)
                .pipe(finalize(() => { }))
                .subscribe(result => {

                    
                    this.clientproviderlist = result;
                    if (this.clientproviderlist.length > 0) {
                        for (let i = 0; i < this.clientproviderlist.length; i++) {
                            if (this.clientproviderlist[i].typeOfProvider) {
                                var typofPrv = this.setprovidertype(this.clientproviderlist[i].typeOfProvider);
                                this.clientproviderlist[i].typeOfProviderName = typofPrv[0].providerType.name;
                            }
                            if (this.clientproviderlist[i].site) { 
                                var sitename = this.settreatmentsite(this.clientproviderlist[i].site.toString());
                                this.clientproviderlist[i].siteName = sitename[0].name;
                            }
                        }
                    }
                    this.CommonProviderModalemitter.emit(this.clientproviderlist);
                });

            //this._clientsServiceProxy.getClientForEdit(clientId)
            //    .pipe(finalize(() => { }))
            //    .subscribe(result => {

            //        this.Client = result.client;             
            //        this.clientproviderlist = this.Client.clientProviderinfo;
            //        });
        }
    }


    settreatmentsite(pid: string): any {
        if (this.TreatmentSite)
            return this.TreatmentSite.filter(x => x.value == pid);
    }


    setprovidertype(pid: number): any {
        if (this.ProviderType)
            return this.ProviderType.filter(x => x.providerType.id == pid);
    }

}
