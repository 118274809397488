import { Component, Input, Output, EventEmitter } from "@angular/core";
import * as SurveyKo from "survey-knockout";
import * as SurveyCreator from "survey-creator";
import * as widgets from "surveyjs-widgets";
import { init as initCustomWidget } from "./customwidget";
import { Survey } from "survey-angular";



widgets.icheck(SurveyKo);
widgets.select2(SurveyKo);
widgets.inputmask(SurveyKo);
widgets.jquerybarrating(SurveyKo);
widgets.jqueryuidatepicker(SurveyKo);
widgets.nouislider(SurveyKo);
widgets.select2tagbox(SurveyKo);
//widgets.signaturepad(SurveyKo);
widgets.sortablejs(SurveyKo);
widgets.ckeditor(SurveyKo);
widgets.autocomplete(SurveyKo);
widgets.bootstrapslider(SurveyKo);
//widgets.emotionsratings(SurveyKo);
initCustomWidget(SurveyKo);

SurveyCreator.StylesManager.applyTheme("default");
// Change item editor icons
SurveyCreator.SurveyNestedPropertyEditorItem.dragIconName = "icon-custom-drag";
SurveyCreator.SurveyNestedPropertyEditorItem.deleteIconName = "icon-custom-delete";
SurveyKo.settings.allowShowEmptyTitleInDesignMode = false;




// var CkEditor_ModalEditor = {
//   afterRender: function(modalEditor, htmlElement) {
//     var editor = window["CKEDITOR"].replace(htmlElement);
//     editor.on("change", function() {
//       modalEditor.editingValue = editor.getData();
//     });
//     editor.setData(modalEditor.editingValue);
//   },
//   destroy: function(modalEditor, htmlElement) {
//     var instance = window["CKEDITOR"].instances[htmlElement.id];
//     if (instance) {
//       instance.removeAllListeners();
//       window["CKEDITOR"].remove(instance);
//     }
//   }
// };
// SurveyCreator.SurveyPropertyModalEditor.registerCustomWidget(
//   "html",
//   CkEditor_ModalEditor
// );
// CKEditor is the third party library
/* Steps to use CKEditor in the project:
    1. run 'npm i ckeditor'
    2. update the "build" section of the angular.json: add ckeditor.js script in the scripts section.
*/

@Component({
  selector: "surveycreator",
    template: `

 <div id="surveyCreatorContainer"></div>


  `
})
export class SurveyCreatorComponent {
  surveyCreator: SurveyCreator.SurveyCreator;
  @Input() json: any;
  @Output() surveySaved: EventEmitter<Object> = new EventEmitter();
    ngOnInit() {

    SurveyKo.JsonObject.metaData.addProperty(
      "questionbase",
      "popupdescription:text"
    );
        SurveyKo.JsonObject.metaData.addProperty("page", "popupdescription:text");
      this.json = {};

      let options = {
          showEmbededSurveyTab: false, generateValidJSON: true, includeEmpty: true, includeQuestionTypes: true, pageEditMode: "single",
          //questionTypes: ["text", "checkbox", "radiogroup", "dropdown"]
           hideExpressionHeaderInLogicTab: false, showSurveyTitle: "never",
          showTitlesInExpressions: false, allowControlSurveyTitleVisibility: false,showLogicTab: true
      };
    this.surveyCreator = new SurveyCreator.SurveyCreator(
      "surveyCreatorContainer",
      options
        );

        // Remove toolbar items except undo/redo buttons
        this.surveyCreator
            .toolbarItems
            .splice(1, 5);

    this.surveyCreator.text = JSON.stringify(this.json);
    //this.surveyCreator.saveSurveyFunc = this.saveMySurvey;
    this.surveyCreator.showToolbox = "right";
    this.surveyCreator.showPropertyGrid = "right";
    this.surveyCreator.rightContainerActiveItem("toolbox");
    this.surveyCreator.haveCommercialLicense = true;
    //this.surveyCreator.showState = false;

        //Toolbox removeableitems
        this.surveyCreator.toolbox.removeItem("barrating");
        this.surveyCreator.toolbox.removeItem("rating");
        this.surveyCreator.toolbox.removeItem("ranking");
        this.surveyCreator.toolbox.removeItem("imagepicker");
        this.surveyCreator.toolbox.removeItem("boolean");
        this.surveyCreator.toolbox.removeItem("image");
        this.surveyCreator.toolbox.removeItem("html");
        this.surveyCreator.toolbox.removeItem("signaturepad");
        this.surveyCreator.toolbox.removeItem("expression");
        this.surveyCreator.toolbox.removeItem("file");


        this.surveyCreator.toolbox.removeItem("matrix");
        this.surveyCreator.toolbox.removeItem("matrixdropdown");
        this.surveyCreator.toolbox.removeItem("matrixdynamic");
        this.surveyCreator.toolbox.removeItem("multipletext");
        this.surveyCreator.toolbox.removeItem("paneldynamic");
        this.surveyCreator.toolbox.removeItem("nouislider");
        this.surveyCreator.toolbox.removeItem("tagbox");
        this.surveyCreator.toolbox.removeItem("sortablelist");
        this.surveyCreator.toolbox.removeItem("bootstrapslider");
        this.surveyCreator.toolbox.removeItem("textwithbutton");
        this.surveyCreator.toolbox.removeItem("datepicker");
        this.surveyCreator.toolbox.removeItem("comment");

      
      
    this.surveyCreator.onTestSurveyCreated.add(function (sender, options) {
        options.survey.showCompletedPage = false;

        options.survey.onComplete.add(function (sender, options) {
            //Do something on completing the survey

        });
              
        });
  }
    AssigntemplateJSON(event) {
        
        if (event) {
            this.json = JSON.parse(event);
        }
        else {
            this.json = "";
        }
       
        let options = {
           // showEmbededSurveyTab: false, showJSONEditorTab: true, generateValidJSON: true, includeEmpty: true, includeQuestionTypes: true, pageEditMode: "single",
             showTitlesInExpressions: false, allowEditExpressionsInTextEditor: false, hideExpressionHeaderInLogicTab: false, showSurveyTitle: "never", allowControlSurveyTitleVisibility: false,showLogicTab: true
        };
        this.surveyCreator = new SurveyCreator.SurveyCreator("surveyCreatorContainer", options);

        // Remove toolbar items except undo/redo buttons
        this.surveyCreator
            .toolbarItems
            .splice(1, 5);

        if (this.json) {
            this.surveyCreator.text = JSON.stringify(this.json);
        }
        else {
            this.surveyCreator.text = this.json;
        }
               
        //To disable Save button
       // this.surveyCreator.saveSurveyFunc = this.saveMySurvey;

        this.surveyCreator.showToolbox = "right";
        this.surveyCreator.showPropertyGrid = "right";
        this.surveyCreator.rightContainerActiveItem("toolbox");
        this.surveyCreator.haveCommercialLicense = true;

       //Toolbox removeableitems
        this.surveyCreator.toolbox.removeItem("barrating");
        this.surveyCreator.toolbox.removeItem("rating");
        this.surveyCreator.toolbox.removeItem("ranking");
        this.surveyCreator.toolbox.removeItem("imagepicker");
        this.surveyCreator.toolbox.removeItem("boolean");
        this.surveyCreator.toolbox.removeItem("image");
        this.surveyCreator.toolbox.removeItem("html");
        this.surveyCreator.toolbox.removeItem("signaturepad");
        this.surveyCreator.toolbox.removeItem("expression");
        this.surveyCreator.toolbox.removeItem("file");
        

        this.surveyCreator.toolbox.removeItem("matrix");
        this.surveyCreator.toolbox.removeItem("matrixdropdown");
        this.surveyCreator.toolbox.removeItem("matrixdynamic");
        this.surveyCreator.toolbox.removeItem("multipletext");
        this.surveyCreator.toolbox.removeItem("paneldynamic");
        this.surveyCreator.toolbox.removeItem("nouislider");
        this.surveyCreator.toolbox.removeItem("tagbox");
        this.surveyCreator.toolbox.removeItem("sortablelist");
        this.surveyCreator.toolbox.removeItem("bootstrapslider");
        this.surveyCreator.toolbox.removeItem("textwithbutton");
        this.surveyCreator.toolbox.removeItem("datepicker"); 
        this.surveyCreator.toolbox.removeItem("comment");

        

       // this.surveySaved = new EventEmitter();
        //this.surveyCreator.JSON = JSON.stringify(event);
        //this.surveyCreator.showState = true;

    }

  saveMySurvey = () => {
      console.log(JSON.parse(this.surveyCreator.text));
      this.surveySaved.emit(JSON.parse(this.surveyCreator.text));
     
  };

   
}
