import { Component, ViewChild, Injector, Output, EventEmitter } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ModalDirective } from 'ngx-bootstrap';
import { CreateOrEditClientAllergyDto, ClientsServiceProxy, CommonServiceProxy, CreateOrEditClientDto, CommonComponentServiceProxy } from '@shared/service-proxies/service-proxies';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
//import { resetBlazorTemplate } from '@syncfusion/ej2-base';
//import { CreateOrEditClientAllergyDto  } from '@shared/service-proxies/service-proxies';

@Component({
    selector: 'commonAllergyModal',
    templateUrl: './common-allergy-modal.component.html'
})

export class CommonAllergyModalComponent extends AppComponentBase {

    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();
    @Output()  CommonAllergyModalemitter: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('commonAllergyModal') modal: ModalDirective;

    clientallergylist: CreateOrEditClientAllergyDto[] = new Array();
    Client: CreateOrEditClientDto = new CreateOrEditClientDto();

    constructor(
        injector: Injector,

         private _clientsServiceProxy: ClientsServiceProxy,
        private _commonServiceProxy: CommonServiceProxy,
        private _commonCompServiceProxy: CommonComponentServiceProxy,
        private route: ActivatedRoute
    ) {
        super(injector);
    }

    show(clientId?: number): void {
        
        if (clientId) {
            //this._clientsServiceProxy.getClientForEdit(clientId)
            this._commonCompServiceProxy.getAllergyList(clientId) 
                .pipe(finalize(() => { }))
                .subscribe(result => {
                    this.clientallergylist = result;
                    //this.Client = result.client;
                    //this.clientallergylist = this.Client.clientallergyInfo;
                 //   this.CommonAllergyModalemitter.emit(this.clientallergylist);
                    
                    this.CommonAllergyModalemitter.emit(this.clientallergylist);
                });
        }
    }   

}
