import { Injector, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AbpMultiTenancyService } from '@abp/multi-tenancy/abp-multi-tenancy.service';
import { AbpSessionService } from '@abp/session/abp-session.service';
import { ImpersonationService } from '@app/admin/users/impersonation.service';
import { AppAuthService } from '@app/shared/common/auth/app-auth.service';
import { LinkedAccountService } from '@app/shared/layout/linked-account.service';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ChangeUserLanguageDto, LinkedUserDto, ProfileServiceProxy, UserLinkServiceProxy } from '@shared/service-proxies/service-proxies';
import * as _ from 'lodash';
import { RcopiaPopupComponent } from './rcopia-popup/rcopia-popup.component';
declare function gmtTime(): any;
declare function calcMD5(str): string;
@Component({
    templateUrl: './topbar.component.html',
    selector: 'topbar',
    encapsulation: ViewEncapsulation.None
})
export class TopBarComponent extends AppComponentBase implements OnInit {

    languages: abp.localization.ILanguageInfo[];
    currentLanguage: abp.localization.ILanguageInfo;
    isImpersonatedLogin = false;
    isMultiTenancyEnabled = false;
    shownLoginName = '';
    tenancyName = '';
    userName = '';
    profilePicture = AppConsts.appBaseUrl + '/assets/common/images/default-profile-picture.png';
    defaultLogo = AppConsts.appBaseUrl + '/assets/common/images/app-logo-on-' + this.currentTheme.baseSettings.menu.asideSkin + '.svg';
    recentlyLinkedUsers: LinkedUserDto[];
    unreadChatMessageCount = 0;
    remoteServiceBaseUrl: string = AppConsts.remoteServiceBaseUrl;
    chatConnected = false;
    recopiaNotification: any[];
    @ViewChild('rcopiaPopUpModal') rcopiaPopUpModal: RcopiaPopupComponent;
    constructor(
        injector: Injector,
        private _abpSessionService: AbpSessionService,
        private _abpMultiTenancyService: AbpMultiTenancyService,
        private _profileServiceProxy: ProfileServiceProxy,
        private _userLinkServiceProxy: UserLinkServiceProxy,
        private _authService: AppAuthService,
        private _impersonationService: ImpersonationService,
        private _linkedAccountService: LinkedAccountService
    ) {
        super(injector);
    }

    ngOnInit() {
        this.isMultiTenancyEnabled = this._abpMultiTenancyService.isEnabled;
        this.languages = _.filter(this.localization.languages, l => (l).isDisabled === false);

        this.currentLanguage = this.localization.currentLanguage;
        this.isImpersonatedLogin = this._abpSessionService.impersonatorUserId > 0;

        this.setCurrentLoginInformations();
        this.getProfilePicture();
        this.getRecentlyLinkedUsers();
        this.getRcopiaNotification();
        this.registerToEvents();
    }
    getRcopiaNotification() {
        this._profileServiceProxy.getAllRcopiaNotificaitons().subscribe(result => {
            this.recopiaNotification = [];
            this.recopiaNotification = result;
        });
    }
    registerToEvents() {
        abp.event.on('profilePictureChanged', () => {
            this.getProfilePicture();
        });

        abp.event.on('app.chat.unreadMessageCountChanged', messageCount => {
            this.unreadChatMessageCount = messageCount;
        });

        abp.event.on('app.chat.connected', () => {
            this.chatConnected = true;
        });

        abp.event.on('app.getRecentlyLinkedUsers', () => {
            this.getRecentlyLinkedUsers();
        });

        abp.event.on('app.onMySettingsModalSaved', () => {
            this.onMySettingsModalSaved();
        });
    }

    changeLanguage(languageName: string): void {
        const input = new ChangeUserLanguageDto();
        input.languageName = languageName;

        this._profileServiceProxy.changeLanguage(input).subscribe(() => {
            abp.utils.setCookieValue(
                'Abp.Localization.CultureName',
                languageName,
                new Date(new Date().getTime() + 5 * 365 * 86400000), //5 year
                abp.appPath
            );

            window.location.reload();
        });
    }
    redirectToRcopia(startUpScreen) {
          
        this.rcopiaPopUpModal.show(startUpScreen);
        //var staticBaseUrl = "action=login&service="
        //var user = this.appSession.user.clientPractitionerinfo;
        //var setting = abp.setting.get("App.RCopia.RCopiaSettings");
        //var rcopiaSetting = JSON.parse(setting);
        //var URL = rcopiaSetting.RegionUrl;
        //var service = rcopiaSetting.Service;
        //var startupScreen = "&startup_screen=" + startUpScreen + "&";
        //var secretKey = rcopiaSetting.SecretKey;
        //var rcopiaPortalSysName = "rcopia_portal_system_name=" + rcopiaSetting.PortalSystemName + "&";
        ////var rcopiaPatientSysName = "&rcopia_patient_system_name=" + rcopiaSetting.PortalSystemName + "&";
        //var closeWindow = "&close_window=n&allow_popup_screens=y&logout_url=http://www.google.com&";
        //var rcopiaUserId = "rcopia_user_id=" + user.rCopiaUserId;
        //var rcopiaPracticeUserName = "&rcopia_practice_user_name=" + rcopiaSetting.RcopiaUsername + "&";
        //var limpMode = "limp_mode=y";
        //var time = "time=" + gmtTime();

        //var RcopiaUrl = staticBaseUrl + service + startupScreen + rcopiaUserId + rcopiaPracticeUserName + rcopiaPortalSysName + limpMode + closeWindow + time + secretKey;
        //var RcopiaUrlWithOutSecretKey = staticBaseUrl + service + startupScreen + rcopiaUserId + rcopiaPracticeUserName + rcopiaPortalSysName + limpMode + closeWindow + time;
        //var MAC = "&MAC=" + calcMD5(RcopiaUrl).toUpperCase();

        //var finalUrl = URL + "?" + RcopiaUrlWithOutSecretKey + MAC;
        //this.openLoginWindow(finalUrl);
    }
    openLoginWindow(url) {
        //this.windowRef = window.open(url, "child", "toolbar=no,location=no,directories=no,status=no,menubar=no,titlebar=no,fullscreen=no,scrollbars=1,resizable=no,width=900,height=600,left=500,top=100");
        var win = window.open(url, "child", "toolbar=no,location=no,directories=no,status=no,menubar=no,titlebar=no,fullscreen=no,scrollbars=1,resizable=no,width=900,height=600,left=500,top=100");
        var timer = setInterval(() => {
              
            if (win.closed) {
                  
                clearInterval(timer);
            }
        }, 1000);
    }
    setCurrentLoginInformations(): void {
        this.shownLoginName = this.appSession.getShownLoginName();
        this.tenancyName = this.appSession.tenancyName;
        // this.userName = this.appSession.user.userName;

        if (this.appSession.user.name.toLowerCase().trim() == this.appSession.user.surname.toLowerCase().trim())
            this.userName = this.appSession.user.name;
        else
            this.userName = this.appSession.user.name + " " + this.appSession.user.surname;
    }

    getShownUserName(linkedUser: LinkedUserDto): string {
        if (!this._abpMultiTenancyService.isEnabled) {
            return linkedUser.username;
        }

        return (linkedUser.tenantId ? linkedUser.tenancyName : '.') + '\\' + linkedUser.username;
    }

    getProfilePicture(): void {
        this._profileServiceProxy.getProfilePicture().subscribe(result => {
            if (result && result.profilePicture) {
                this.profilePicture = 'data:image/jpeg;base64,' + result.profilePicture;
            }
        });
    }

    getRecentlyLinkedUsers(): void {
        this._userLinkServiceProxy.getRecentlyUsedLinkedUsers().subscribe(result => {
            this.recentlyLinkedUsers = result.items;
        });
    }


    showLoginAttempts(): void {
        abp.event.trigger('app.show.loginAttemptsModal');
    }

    showLinkedAccounts(): void {
        abp.event.trigger('app.show.linkedAccountsModal');
    }

    changePassword(): void {
        abp.event.trigger('app.show.changePasswordModal');
    }

    changeProfilePicture(): void {
        abp.event.trigger('app.show.changeProfilePictureModal');
    }

    changeMySettings(): void {
        abp.event.trigger('app.show.mySettingsModal');
    }

    logout(): void {
        this._authService.logout();
    }

    onMySettingsModalSaved(): void {
        this.shownLoginName = this.appSession.getShownLoginName();
    }

    backToMyAccount(): void {
        this._impersonationService.backToImpersonator();
    }

    switchToLinkedUser(linkedUser: LinkedUserDto): void {
        this._linkedAccountService.switchToAccount(linkedUser.id, linkedUser.tenantId);
    }

    get chatEnabled(): boolean {
        return (!this._abpSessionService.tenantId || this.feature.isEnabled('App.ChatFeature'));
    }

    downloadCollectedData(): void {
        this._profileServiceProxy.prepareCollectedData().subscribe(() => {
            this.message.success(this.l('GdprDataPrepareStartedNotification'));
        });
    }
}
