import { Component, ViewChild, Injector, Output, EventEmitter } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ModalDirective } from 'ngx-bootstrap';
import { CreateOrEditProgramEnrollmentDto, ClientsServiceProxy, CreateOrEditClientDto, CommonServiceProxy, CommonComponentServiceProxy} from '@shared/service-proxies/service-proxies';
import { Router, ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { CreateOrEditTransitionCarePlanModalComponent } from '@app/main/transitionCarePlans/transitionCarePlans/create-or-edit-transitionCarePlan-modal.component';

@Component({
    selector: 'commonProgramEnrollmentModal',
    templateUrl: './common-programenrollment-modal.component.html'
})

export class CommonProgramEnrollmentModalComponent extends AppComponentBase {

    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();
    @Output() CommonProgramEnrollmentModalemitter: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('commonProgramEnrollmentModal') modal: ModalDirective;
    Client: CreateOrEditClientDto = new CreateOrEditClientDto();
    @ViewChild('createOrEditTransitionCarePlanModal') createOrEditTransitionCarePlanModal: CreateOrEditTransitionCarePlanModalComponent;
    programenrollmentlist: CreateOrEditProgramEnrollmentDto[] = new Array();
   // newprogramenrollmentlist: CreateOrEditProgramEnrollmentDto[] = new Array();
    programenrollment: CreateOrEditProgramEnrollmentDto = new CreateOrEditProgramEnrollmentDto();

    clientIdnew: number;
    boolchk: number;
    TreatmentSite: any[];
    programs: any[];
    prgmclosedres: any[];
    LocationSite: any[];
    _chekbox = false;
    private sub: any;

    constructor(
        injector: Injector,
        private _clientsServiceProxy: ClientsServiceProxy,
        private _commonServiceProxy: CommonServiceProxy,
        private _commonCompServiceProxy: CommonComponentServiceProxy,
        private route: ActivatedRoute       
    ) {
        super(injector);
       
    }

    ngOnInit() {
       
        this._commonServiceProxy.gethealthcareservice().subscribe(result => {
            this.programs = result;
        });

        this._commonServiceProxy.getTreatmentsiteall().subscribe(result => {

            this.TreatmentSite = result;
        });

        this._commonServiceProxy.getTreatmentsites().subscribe(result => {
            this.LocationSite = result;
        });
      
    }

    show(clientId?: number , boolchk?:number): void {
       
        this.clientIdnew = clientId
        
        //if (boolchk) {
        //    if (boolchk==1)
        //    this._chekbox = true;
        //}
        //else
        //    this._chekbox = false;

        if (clientId) {
            //this._clientsServiceProxy.getClientForEdit(clientId)
            this._commonCompServiceProxy.getProgrameEnrollmentlist(clientId)
            .pipe(finalize(() => { }))
            .subscribe(result => {
               
                //this.Client = result.client;
                //this.Client.programEnrollmentInfo = result//this.Client.programEnrollmentInfo.filter(x => x.status == 1);
                //this.Client.programEnrollmentInfo = this.Client.programEnrollmentInfo.filter(x => this.LocationSite)
                this.programenrollmentlist = result;//this.Client.programEnrollmentInfo;
                //this.newprogramenrollmentlist = this.programenrollmentlist.filter(x => x.status == 1 && x.programChack == true);
                
                if (this.programenrollmentlist.length > 0) {
                    for (let i = 0; i < this.programenrollmentlist.length; i++) {
                        if (this.programenrollmentlist[i].treatmentSite) {
                            var trtmentsite = this.settreatmentsite(this.programenrollmentlist[i].treatmentSite.toString());
                            this.programenrollmentlist[i].treatmentSiteName = trtmentsite[0].name;
                        }
                        if (this.programenrollmentlist[i].program) {
                            var progm = this.setprograms(this.programenrollmentlist[i].program);
                            this.programenrollmentlist[i].programName = progm[0].healthCareService.name;
                        }
                    }
                }
                this.CommonProgramEnrollmentModalemitter.emit(this.programenrollmentlist);
                });
        }
    }
  
    settreatmentsite(pid: string): any {
        if (this.TreatmentSite)
            return this.TreatmentSite.filter(x => x.value == pid);
    }

    setprograms(pid: number): any {
        if (this.programs)
            return this.programs.filter(x => x.healthCareService.id == pid);
    }
    
    //homelesschange(data: CreateOrEditProgramEnrollmentDto[] ) {
    //   
    //    if(this.programenrollmentlist)
    //        this.newprogramenrollmentlist = this.programenrollmentlist.filter(x => x.status == 1 && x.programChack == true);
    //    data = this.newprogramenrollmentlist;
    //}

    //getselectedList() {       
    //   
    //    if (this.programenrollmentlist) { 
    //        this.newprogramenrollmentlist = this.programenrollmentlist.filter(x => x.status == 1 && x.programChack == true);           
    //        this.modalSave.emit(this.newprogramenrollmentlist);
    //    }
    //   // return this.newprogramenrollmentlist;
    //}
}

