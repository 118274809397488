import { Component, ViewChild, Injector, HostListener, OnInit } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { AppComponentBase } from '../../../../shared/common/app-component-base';
import { ClientsServiceProxy } from '../../../../shared/service-proxies/service-proxies';
declare function gmtTime(): any;
declare function calcMD5(str): string;

@Component({
    selector: 'rcopiaPopUpModal',
    templateUrl: './rcopia-popup.component.html',
    styleUrls: ['./rcopia-popup.component.css']
})
export class RcopiaPopupComponent extends AppComponentBase implements OnInit {
    @ViewChild('RcopiaPopUp') RcopiaPopUp: ModalDirective;
    constructor(
        injector: Injector,
        private _clientsServiceProxy: ClientsServiceProxy,
        private sanitizer: DomSanitizer,
    ) {
        super(injector);
        this.onResize();
    }

    type = "";
    timeStamp: any;
    finalUrl = "";
    iframe: any;
    screenHeight;
    screenWidth;
    modalheight = 0;
    LocationSite: any[];
    site: any;
    showFrame = false;
    clientId: any;
    hasLocationId = false;
    @HostListener('window:resize', ['$event'])
    onResize(event?) {
        console.log('width :' + window.innerHeight)
        this.screenHeight = window.innerHeight;
        this.modalheight = this.screenHeight - 55;
        this.screenWidth = window.innerWidth - 50;
    }

    ngOnInit() {
    }
    show(type) {
        this.finalUrl = "";
        this.type = "";
        this.timeStamp = null;
        this.type = type;
        this.redirectToRcopia(this.type);
    }

    RcopiaClose() {
        this.finalUrl = '';
        this.updateMedication();
        this.RcopiaPopUp.hide();
    }
    redirectToRcopia(startUpScreen) {
          
       
        var staticBaseUrl = "action=login&service="
        var user = this.appSession.user.clientPractitionerinfo;
        var setting = abp.setting.get("App.RCopia.RCopiaSettings");
        var rcopiaSetting = JSON.parse(setting);
        var URL = rcopiaSetting.RegionUrl;
        var service = rcopiaSetting.Service;
        var startupScreen = "&startup_screen=" + startUpScreen + "&";
        var secretKey = rcopiaSetting.SecretKey;
        var rcopiaPortalSysName = "rcopia_portal_system_name=" + rcopiaSetting.PortalSystemName + "&";
        var closeWindow = "&close_window=n&allow_popup_screens=n&logout_url=http://www.google.com&";
        var rcopiaUserId = "rcopia_user_id=" + user.rCopiaUserId;
        var rcopiaPracticeUserName = "&rcopia_practice_user_name=" + rcopiaSetting.RcopiaUsername + "&";
        var limpMode = "limp_mode=y";
        var time = "time=" + gmtTime();
        this.timeStamp = gmtTime();
        var RcopiaUrl = staticBaseUrl + service + startupScreen + rcopiaUserId + rcopiaPracticeUserName + rcopiaPortalSysName + limpMode + closeWindow + time + secretKey;
        var RcopiaUrlWithOutSecretKey = staticBaseUrl + service + startupScreen + rcopiaUserId + rcopiaPracticeUserName + rcopiaPortalSysName + limpMode + closeWindow + time;
        var MAC = "&MAC=" + calcMD5(RcopiaUrl).toUpperCase();

        this.finalUrl = URL + "?" + RcopiaUrlWithOutSecretKey + MAC;
        this.iframe = this.sanitizer.bypassSecurityTrustResourceUrl(this.finalUrl);
        this.RcopiaPopUp.show();
    }
    updateMedication() {
          
        this._clientsServiceProxy.updateMedicationFromRcopia(this.timeStamp).subscribe(result => {
              
            setTimeout(() => {

            }, 5000);
        });
    }
}
