import { Component, ViewChild, Injector, Output, EventEmitter } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ModalDirective } from 'ngx-bootstrap';
import { ClientsServiceProxy, CommonServiceProxy, CreateOrEditClientDto, CreateOrEditHospitalizationDto, CommonComponentServiceProxy, PagedResultDtoOfLanguageTextListDto } from '@shared/service-proxies/service-proxies';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'commonhospitalizationmodal',
    templateUrl: './common-hospitalization-modal.component.html'
})

export class commonhospitalizationmodalcomponent extends AppComponentBase {

    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();
    @Output() Commonhospitalizationmodalemitter: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('commonhospitalizationmodal') modal: ModalDirective;

    hospitalizationlist: CreateOrEditHospitalizationDto[] = new Array();
    Client: CreateOrEditClientDto = new CreateOrEditClientDto();
    Phychiatric: any[];

    constructor(
        injector: Injector,

        private _clientsServiceProxy: ClientsServiceProxy,
        private _commonServiceProxy: CommonServiceProxy,
        private _commonCompServiceProxy: CommonComponentServiceProxy,
        private route: ActivatedRoute
    ) {
        super(injector);
        this._commonServiceProxy.getClientPhychiatric().subscribe(result => {
            this.Phychiatric = result;
        });
    }

    show(clientId?: number): void {
        
        if (clientId) {
            //this._clientsServiceProxy.getClientForEdit(clientId)
            this._commonCompServiceProxy.getHospitalizationList(clientId) 
                .pipe(finalize(() => { }))
                .subscribe(result => {
                    this.hospitalizationlist = result;
                    //this.Client = result.client;
                    //this.hospitalizationlist = this.Client.hospitalizationinfo;

                    
                    if (this.hospitalizationlist.length > 0) {
                        for (let i = 0; i < this.hospitalizationlist.length; i++) {
                            if (this.hospitalizationlist[i].psysom) {
                                var hospitalization = this.setPhychiatric(this.hospitalizationlist[i].psysom);
                                this.hospitalizationlist[i].psysomName = hospitalization[0].phychiatric.name;
                            }                            
                        }
                    }

                    this.Commonhospitalizationmodalemitter.emit(this.hospitalizationlist);
                });
        }
    }

    setPhychiatric(pid: number): any {
        if (this.Phychiatric)
            return this.Phychiatric.filter(x => x.phychiatric.id == pid);
    }

}
