import { Component, ViewChild, Injector, Output, EventEmitter, OnInit, ElementRef } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { finalize } from 'rxjs/operators';
import {
    ICCN_BillsServiceProxy, CreateOrEditICCN_BillDto, ICCN_BillIndividualClinicalNoteLookupTableDto, CreateOrEditIndividual_Masters_ValueDto, Individual_Masters_ValuesServiceProxy, CommonServiceProxy, UserListDto, SvcsInvoicesServiceProxy
} from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
import * as moment from 'moment';




@Component({
    selector: 'electronicallysignedbymodal',
    templateUrl: './electronically-signed-by-modal.component.html'
})
export class electronicallysignedbymodalComponent extends AppComponentBase implements OnInit {
    @ViewChild('electronicallysignedbymodalForm') form;
    constructor(
        injector: Injector       
    ) {
        super(injector);
      
    }

    ngOnInit(): void {
       

    }

    
}
