import { Component, ViewChild, Injector, Output, EventEmitter, Input } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { finalize } from 'rxjs/operators';
import { AcknowledgementsServiceProxy, CreateOrEditAcknowledgementDto, NameValueOfString, CommonServiceProxy, NameValueDto, IntakeClientsServiceProxy } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
import * as moment from 'moment';
import { Router } from '@angular/router';
import * as _ from 'lodash';


@Component({
    selector: 'createOrEditAcknowledgementModal',
    templateUrl: './create-or-edit-acknowledgement-modal.component.html'
})
export class CreateOrEditAcknowledgementModalComponent extends AppComponentBase {

    @ViewChild('createOrEditModal') modal: ModalDirective;
    @ViewChild('acknowledgementForm') form;
    @ViewChild('createOrEditAcknowledgementModal') createOrEditAcknowledgementModal: CreateOrEditAcknowledgementModalComponent;
    @Output() intakeAcknowledgementsave: EventEmitter<any> = new EventEmitter<any>();
    @Output() intakeAcknowledgementredirect: EventEmitter<any> = new EventEmitter<any>();
    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

    @Input() childReferralID: string;
    @Input() childClientID: string;


    receivedReferralID: string;
    receivedClientID: string;
    visibility: boolean = true;

    checkAckFlag: any;

    OpenforPrac: boolean = true;
    DisablePrac: boolean = false;
    DisableAddButton: boolean = false;
    DisableDeleteButton: boolean = false;
    DisableEditButton: boolean = false;
    ratings: any[];
    active = false;
    saving = false;
    originalobj: any;
    acknowledgementold: any;
    _formsubmitted = false;
    _required = false;
    practioners: any[];
    _edit = false;
    _idx?: number = null;
    userId: number;
    acknowledgement: CreateOrEditAcknowledgementDto = new CreateOrEditAcknowledgementDto();
    clientpractioners: NameValueOfString = new NameValueOfString();
    _showError: boolean = false;
    public _isOpenAcknowledge: boolean = false;
    _intakeAckadddisable = false;
    AcknowledgementList: CreateOrEditAcknowledgementDto[] = new Array();
    today: Date;

    constructor(
        injector: Injector,
        private _commonServiceProxy: CommonServiceProxy,
        private _router: Router,
        private _intakeClientsServiceProxy: IntakeClientsServiceProxy,
        private _acknowledgementsServiceProxy: AcknowledgementsServiceProxy
    ) {
        super(injector);
        this.userId = this.appSession.user.id;


    }

    ngOnInit() {
        this._commonServiceProxy.getQARating().subscribe(result => {
            this.ratings = result;
        });

    }

    //Report DDL
    ReportValueOnChange(event) {
        var searchValue = event.target.value.trim();
        if (!searchValue.length) {
            (<HTMLInputElement>document.getElementById(event.target.id)).value = '';
        }


        var Objproprtyname = event.srcElement.id.split("_");
        var propertyName = _.camelCase(Objproprtyname[1]) + "Name";
        this.acknowledgement[propertyName] = event.currentTarget.selectedOptions[0].innerText;
    }

    show(acknowledgementId?: number): void {
          

        //this.OpenforPrac = true;
        //this.DisablePrac = true;

        this._formsubmitted = false;
        this._required = false;

        if (this._edit == false)
            this.clientpractioners = null;
        if (acknowledgementId == 0)
            acknowledgementId = null;


        this._acknowledgementsServiceProxy.getAcknowledgementForEdit(acknowledgementId).subscribe(result => {
            this.acknowledgement = result.acknowledgement;
            this.acknowledgement.id = Math.floor(Math.random() * (999999999999 - 100000000000)) + 100000000000;
            this.active = true;
            this.originalobj = Object.assign({}, this.acknowledgement);
            this.acknowledgementold = Object.assign({}, this.acknowledgement);
            this._formsubmitted = false;
            // this.clientpractioners.name = this.acknowledgement.practitionerName;
            this.modal.show();
        });

        //
        //for (let i = 0; i < this.intakeAcknowledgementList.length; i++) {
        //    if (this.intakeAcknowledgementList[i].userId == this.userID)
        //    {
        //        this.blockedDocument = true;
        //        this._isOpenAcknowledge = true;
        //    }
        //}
    }

    save(buttonType) {
          
        this._formsubmitted = true;
        this._required = true;
        let acknow = new CreateOrEditAcknowledgementDto();
        if (this.form.valid === false)
            return false;
        else
            this.acknowledgementold = null;

        if (this._edit)
            this.AcknowledgementList.splice(this._idx, 1);
        acknow.init(this.acknowledgement);

        if (this.DisablePrac == true) {
            if (this.acknowledgement.userType == 2) {
                if (this.acknowledgement.signatoryConsent == false) {
                    this.message.info("Please read all the information and tick the consent to sign");
                    return false;
                }
            }
        }
        

        if (buttonType === "save") {
            this._showError = false;
            this.AcknowledgementList.push(acknow)
            this._edit = false;
            this._idx = null;
            this.intakeAcknowledgementsave.emit(this.AcknowledgementList);
        }

        if (buttonType === "acknowledge") {
            this._showError = false;
            acknow.acknowledgementFlag = true;

            acknow.creationTime = moment(this.today);
            acknow.lastModificationTime = moment(this.today);
            this.AcknowledgementList.push(acknow)
            this._edit = false;
            this._idx = null;
            this.intakeAcknowledgementsave.emit(this.AcknowledgementList);
            //this._acknowledgementsServiceProxy.createOrEdit(acknow)
            //    .pipe(finalize(() => { this.saving = false; }))
            //    .subscribe(() => {
            //       
            //this.AcknowledgementList.push(acknow)
            //this.intakeAcknowledgementsave.emit(this.intakeAcknowledgementList);

            //this._acknowledgementsServiceProxy.ackFlagStatus(acknow.entityId, acknow.formId).subscribe(result => {
            //   
            //    this.checkAckFlag = result;
            //    if (this.checkAckFlag == true) {

            //        this._intakeClientsServiceProxy.updateAcknowledgmentFlagIntake(acknow.entityId).subscribe(result => {
            //           
            //        });
            //    }
            //    this.intakeAcknowledgementredirect.emit(true);
            //});
            //this.notify.info(this.l('SavedSuccessfully'));
            //this.intakeAcknowledgementredirect.emit(true);
            //this._router.navigate(['app/main/intakeClients/intakeClients']);
            // this.close();
            //});

            //this.intakeAcknowledgementsave.emit();
        }

        this.modal.hide();
    }

    editintakeacknowledgepopup(result?: CreateOrEditAcknowledgementDto, idx?: number): void {
          
        this.clientpractioners = new NameValueDto();
        this._formsubmitted = false;
        this._required = false;
        this.acknowledgementold = null;
        this.originalobj = Object.assign({}, result);
        this.acknowledgementold = Object.assign({}, result);
        this.acknowledgement = this.originalobj;
        if (result.practitionerID) {
            this.clientpractioners.value = result.practitionerID.toString();
        }
        this.clientpractioners.name = result.practitionerName;

        if (this.acknowledgement.userType == 0) {
            this.acknowledgement.userType = 1;
        }
        this.active = true;
        this._edit = true;
        this._idx = idx;


        if (this.originalobj.userId == this.userId) {
            this.OpenforPrac = false;
            this.DisablePrac = true;
        }

        this.modal.show();

    }

    assigndata(acknowledgelst: CreateOrEditAcknowledgementDto[], visible: boolean, status?: string) {

        if (status == "Submitted" || status == "Acknowledged") {
            this.DisableAddButton = true;
            this.DisableDeleteButton = false;
            this.DisableEditButton = false;
            // this.visibility = true;
        }
        else {
            this.DisableAddButton = false;
            this.DisableDeleteButton = true;
            this.DisableEditButton = true;
            //this.visibility = false;
        }
        let acknow: CreateOrEditAcknowledgementDto[] = new Array();

        for (var i = 0; i < acknowledgelst.length; i++) {

            //if (acknowledgelst[i].userId == this.userId && (status == "Submitted" || status == "Acknowledged"))
            if (acknowledgelst[i].userId == this.userId) {

                if (acknowledgelst[i].acknowledgementFlag == false) {
                    if (status == "Submitted" || status == "Acknowledged") {
                        this.DisableAddButton = true;
                        this.DisableEditButton = true;
                        this.DisableDeleteButton = false;
                        acknow = acknowledgelst;
                        this.AcknowledgementList = acknow.filter(s => s.userId == this.userId);
                        //this.intakeAcknowledgementList.filter(s => s.userId == this.userId);
                        this.visibility = visible;
                        return true;
                    }
                }
                else {
                    this.DisableAddButton = true;
                    this.DisableEditButton = false;
                    this.DisableDeleteButton = false;
                    acknow = acknowledgelst;
                    this.AcknowledgementList = acknow.filter(s => s.userId == this.userId);
                    //this.intakeAcknowledgementList.filter(s => s.userId == this.userId);
                    this.visibility = visible;
                    return true;
                }
            }
        }

        this.AcknowledgementList = acknowledgelst;
        this.visibility = visible;

    }

    close(): void {
        //if (this.originalobj.userId == this.userId) {
        //    this.OpenforPrac = false;
        //}
        if (JSON.stringify(this.acknowledgement).replace(/\:null/gi, "\:\"\"").toLowerCase() == JSON.stringify(this.acknowledgementold).replace(/\:null/gi, "\:\"\"").toLowerCase()) {
            this.oldclientboj();
            this.active = false;
            this.modal.hide();
        }
        else {
            this.message.confirm('Are you sure you want to exit this form?', ' ',
                (isConfirmed) => {
                    if (isConfirmed) {
                        this.oldclientboj();

                    }
                }
            );
        }
    }
    oldclientboj() {
        if (this.acknowledgementold) {
            let acknow = new CreateOrEditAcknowledgementDto();

            if (this._edit) {
                this.AcknowledgementList.splice(this._idx, 1);
                acknow.init(this.acknowledgementold);
                this.AcknowledgementList.push(acknow);
                this.intakeAcknowledgementsave.emit(this.AcknowledgementList);
            }
        }
        this._edit = false;
        this._idx = null;
        this.acknowledgementold = null;
        this.active = false;
        this.modal.hide();
    }

    protected selectedInstallationChanged(event: any): void {

          

        if (this.clientpractioners) {
            var temp = this.AcknowledgementList.filter(s => s.userId == event.userId);
            if (temp.length > 0) {
                var ack = this.AcknowledgementList.filter(s => s.userType == this.acknowledgement.userType && s.userId == event.userId);
                var sig = this.AcknowledgementList.filter(s => s.userType == this.acknowledgement.userType && s.userId == event.userId);
                if (this.acknowledgement.userType == 1) {
                    if (ack.length == 1) {
                        this.message.warn("Selected Practitioner already exist for acknowledgement.");
                        this.clientpractioners = null;
                        this.acknowledgement.practitionerID = null;
                        this.acknowledgement.practitionerName = null;
                        this.acknowledgement.practitionerTitle = null;
                        this.acknowledgement.userId = null;
                        this.clientpractioners = null;
                        this.practioners = [];
                        this.practioners = null;
                        this.form.reset();
                    }
                    else {
                        this.acknowledgement.practitionerID = event.practitionerID;
                        this.acknowledgement.practitionerName = event.name;
                        // this.acknowledgement.practitionerTitle = event.licenseRate;
                        this.acknowledgement.practitionerTitle = event.profileLiscence;
                        this.acknowledgement.userId = event.userId;
                    }
                }
                if (this.acknowledgement.userType == 2) {
                    if (sig.length == 1) {
                        this.message.warn("Selected Practitioner already exist for signatory.");
                        this.clientpractioners = null;
                        this.acknowledgement.practitionerID = null;
                        this.acknowledgement.practitionerName = null;
                        this.acknowledgement.practitionerTitle = null;
                        this.acknowledgement.userId = null;
                        this.clientpractioners = null;
                        this.practioners = [];
                        this.practioners = null;
                        this.form.reset();
                    }
                    else {
                        this.acknowledgement.practitionerID = event.practitionerID;
                        this.acknowledgement.practitionerName = event.name;
                        // this.acknowledgement.practitionerTitle = event.licenseRate;
                        this.acknowledgement.practitionerTitle = event.profileLiscence;
                        this.acknowledgement.userId = event.userId;
                    }
                }
            }
            else {
                this.acknowledgement.practitionerID = event.practitionerID;
                this.acknowledgement.practitionerName = event.name;
                // this.acknowledgement.practitionerTitle = event.licenseRate;
                this.acknowledgement.practitionerTitle = event.profileLiscence;
                this.acknowledgement.userId = event.userId;
            }
        }
        else {
            this.acknowledgement.practitionerID = null;
            this.acknowledgement.practitionerName = null;
        }
    }

    protected blurInstallation(event: any): void {
          
        if (this.clientpractioners) {
            if (event.target.value != this.clientpractioners.name)
                this.clearedInstallation(null);
        }
        if (!this.acknowledgement.practitionerID)
            this.clearedInstallation(null);
    }

    protected clearedInstallation(event: any): void {
          
        this.clientpractioners = null;
        this.acknowledgement.practitionerID = null;
        this.acknowledgement.practitionerName = null;
    }

    search(event) {

        var _data = event.query.trim();
        if (_data) {
            this._commonServiceProxy.getclientpractionersAcknowledgement(event.query).subscribe(data => {

                this.practioners = data;
            });
        }
    }


    showError(errorVariable: boolean) {
        this._showError = errorVariable;
        this._isOpenAcknowledge = true;

    }
    AcknowledgementtoggleOpen(): void {

        this._isOpenAcknowledge = !this._isOpenAcknowledge;
    }

    createintakeacknowledgement(): void {

        // this._isOpenAcknowledge = true;
        //this._intakeAckadddisable = true;

        this.modal.show();
        //setTimeout(() => {    //<<<---    using ()=> syntax
        //    this._intakeAckadddisable = false;
        //}, 7000);
    }

    editintakeacknowledge(event: CreateOrEditAcknowledgementDto, i: number): void {

        //this.createOrEditAcknowledgementModal.editintakeacknowledgepopup(event, i);       
        this.editintakeacknowledgepopup(event, i);
    }

    deleteintakeacknowledge(i: number) {

        if (i != -1) {
            this.message.confirm('Are you sure you want to delete the record?', ' ',
                (isConfirmed) => {
                    if (isConfirmed) {
                        this.AcknowledgementList.splice(i, 1);
                    }
                }
            );
        }
    }
    displayAccordian() {
        this._isOpenAcknowledge = !this._isOpenAcknowledge;
    }
}
