import { Component, ViewChild, Injector, Output, EventEmitter } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ModalDirective } from 'ngx-bootstrap';
import { CreateOrEditClientAllergyDto, ClientsServiceProxy, CommonServiceProxy, CreateOrEditClientDto, CreateOrEditMedicationDto, CommonComponentServiceProxy } from '@shared/service-proxies/service-proxies';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
//import { CreateOrEditClientAllergyDto  } from '@shared/service-proxies/service-proxies';

@Component({
    selector: 'commonmedicationmodal',
    templateUrl: './common-medication-modal.component.html'
}) 

export class commonmedicationmodalcomponent extends AppComponentBase {

    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();
    @Output() Commonmedicationmodalemitter: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('commonmedicationmodal') modal: ModalDirective;

    medicationlist: CreateOrEditMedicationDto[] = new Array();
    Client: CreateOrEditClientDto = new CreateOrEditClientDto();

    constructor(
        injector: Injector,

        private _clientsServiceProxy: ClientsServiceProxy,
        private _commonServiceProxy: CommonServiceProxy,
        private _commonCompServiceProxy: CommonComponentServiceProxy,
        private route: ActivatedRoute
    ) {
        super(injector);
    }

    show(clientId?: number): void {
        
        if (clientId) {
            //this._clientsServiceProxy.getClientForEdit(clientId)
            this._commonCompServiceProxy.getMedicationList(clientId) 
                .pipe(finalize(() => { }))
                .subscribe(result => {
                    
                    this.medicationlist = result;
                    //this.Client = result.client;
                    //this.medicationlist = this.Client.medicationinfo;
                    
                    this.Commonmedicationmodalemitter.emit(this.medicationlist);
                });
            
        }
    }

}
