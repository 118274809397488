import { Component, Input, EventEmitter, Output, OnInit, ChangeDetectorRef } from "@angular/core";
import { finalize } from "rxjs/operators";
import * as Survey from "survey-angular";
import * as widgets from "surveyjs-widgets";
import { CreateOrEditExtensionTemplateDto, ExtensionFieldServiceProxy } from "../../../../../shared/service-proxies/service-proxies";

import { init as initCustomWidget } from "./customwidget";

widgets.icheck(Survey);
widgets.select2(Survey);
widgets.inputmask(Survey);
widgets.jquerybarrating(Survey);
widgets.jqueryuidatepicker(Survey);
widgets.nouislider(Survey);
widgets.select2tagbox(Survey);
//widgets.signaturepad(Survey);
widgets.sortablejs(Survey);
widgets.ckeditor(Survey);
widgets.autocomplete(Survey);
widgets.bootstrapslider(Survey);
widgets.prettycheckbox(Survey);
//widgets.emotionsratings(Survey);
initCustomWidget(Survey);

Survey.JsonObject.metaData.addProperty("questionbase", "popupdescription:text");
Survey.JsonObject.metaData.addProperty("page", "popupdescription:text");

Survey.StylesManager.applyTheme("default");

@Component({
    // tslint:disable-next-line:component-selector
    selector: "survey",
    template: `<div class="m-portlet" [ngClass]="{'panel-open': _isextensionfields}" id="_isextensionfields" >
            <div class="m-portlet__head btn btn-secondary" (click)="_isextensionfields = !_isextensionfields;">
                <div class="m-portlet__head-caption">
                    <div class="m-portlet__head-title">
                        <div class="m-portlet__head-tools">
                            <ul class="m-portlet__nav">
                                <li class="m-portlet__nav-item">
                                    <button type="button" class="m-portlet__nav-link btn btn-info  m-btn m-btn--icon m-btn--icon-only m-btn--pill" disabled>
                                        <i class="flaticon-doc" style="color:white"></i>
                                    </button>
                                </li>
                            </ul>
                        </div>
                        <h3 class="m-portlet__head-text">
                            &nbsp;&nbsp;  Extension Fields

                        </h3>
                    </div>
                </div>
                <div class="m-portlet__head-tools">
                    <ul class="m-portlet__nav">
                        <li class="m-portlet__nav-item">
                            <!--<a href="" class="m-portlet__nav-link m-portlet__nav-link--icon">-->
                            <i class="{{_isextensionfields == true ? 'la la-angle-double-up' : 'la la-angle-double-down' }}" style="color: #36a3f7;"></i>
                            <!--</a>-->
                        </li>
                    </ul>
                </div>
            </div>
            <div class="m-portlet__body" [hidden]="!_isextensionfields" >

                <div class="survey-container contentcontainer codecontainer" >
                    <div id="surveyElement"></div>
                </div>
            </div>
        </div>`,
})
export class SurveyComponent implements OnInit {
    @Output() submitSurvey = new EventEmitter<any>();
    @Input() json: any;
    @Input() formId: any;
    //result: any;
    surveyModel;
    templateJsonValues: any;
    externaltemplate: CreateOrEditExtensionTemplateDto = new CreateOrEditExtensionTemplateDto();
    public _isextensionfields: boolean = false;
    public _disabledextension: boolean = false;

    constructor(      
        private _externalServiceProxy: ExtensionFieldServiceProxy,
        private cd: ChangeDetectorRef,
    ) { }

    

    ngOnInit() {
       
        this.json = {
            focusFirstQuestionAutomatic: "false",
            showCompletedPage: "false",
        };


        this.surveyModel = new Survey.Model(this.json);
        Survey.SurveyNG.render("surveyElement", { model: this.surveyModel });
        Survey.surveyStrings.loadingSurvey = "Please wait.";
        this.surveyModel.checkErrorsMode = "onComplete";
        
        //this.surveyModel.onComplete.add((result, options) => {

        //    this.submitSurvey.emit(JSON.stringify(result.data));
        //    this.cd.detectChanges();
        //    //this.result = this.surveyModel.getPlainData();
        //});

       // 

       // this.surveyModel = new Survey.Model(this.json);


       // Survey.SurveyNG.render("surveyElement", { model: this.surveyModel });
    }
    SetSurveyData(formID, JsonTempalte, templateId) {

        if (templateId != 0 && JsonTempalte!='') {

            this._externalServiceProxy.getExistingTemplateForEdit(templateId)
                .pipe(finalize(() => { }))
                .subscribe(x => {
                    if (x.template) {
                        this.json = x.template.templateFieldData;
                        this.surveyModel = new Survey.Model(this.json);
                        if (JsonTempalte) {
                            this.surveyModel.data = JsonTempalte;
                            //this._isextensionfields = true;
                        }
                        else {
                            this.surveyModel.data = "";
                            //this._isextensionfields = true;
                        }

                    }
                    

                   
                    this.surveyModel.checkErrorsMode = "onComplete";
                    Survey.SurveyNG.render("surveyElement", { model: this.surveyModel });

                    //this.surveyModel.onValidateQuestion.add((survey, options) => {
                    //    console.log(survey);
                    //    console.log("error");
                    //});               

                    

                    this.surveyModel.onComplete.add((result, options) => {
                       
                        this.submitSurvey.emit(JSON.stringify(result.data));
                        this.cd.detectChanges();
                        //this.result = this.surveyModel.getPlainData();
                    });

                  
                });
            //window.scrollTo(0, 0);
        }
        else {
            //Get Template based on FormID from ExtensionTemplate Table
            this._externalServiceProxy.getExtensionTemplateForEdit(formID)
                .pipe(finalize(() => { }))
                .subscribe(x => {
                    if (x.template) {
                        this.json = x.template.templateFieldData;
                        this.surveyModel = new Survey.Model(this.json);
                        if (JsonTempalte) {
                            this.surveyModel.data = JsonTempalte;
                            //this._isextensionfields = true;
                        }
                        else {
                            this.surveyModel.data = "";
                            //this._isextensionfields = true;
                        }

                    }
                    else {
                        this.json = {
                            focusFirstQuestionAutomatic: "false",
                            showCompletedPage: "false",
                        };

                        this.surveyModel = new Survey.Model(this.json);
                        this.surveyModel.data = ""; this._isextensionfields = false;
                    }



                    this.surveyModel.checkErrorsMode = "onComplete";
                    Survey.SurveyNG.render("surveyElement", { model: this.surveyModel });
                    

                    //this.surveyModel.onValidateQuestion.add(function (sender, options) {
                    //    console.log("error");
                    //    if (options.name == "rateMe") {
                    //        if (options.value < 1 || options.value > 9) options.error = "Please enter value from 1 till 9.";
                    //    }
                    //});

                    this.surveyModel.onComplete.add((result, options) => {
                      
                        this.submitSurvey.emit(JSON.stringify(result.data));
                        this.cd.detectChanges();
                        //this.result = this.surveyModel.getPlainData();
                    });


                });
          //  window.scrollTo(0, 0);
        }


        
    }

    surveydisplayaccordian() {
        this._isextensionfields = true;
        window.scrollTo(0, 0);
    }
    disablesurvey() {
        this._disabledextension = true;
        window.scrollTo(0, 0);
    }

    surveyValidateQuestion(s, options) {
        console.log(s);
        window.scrollTo(0, 0);
    }

    setFormID(formid, templateJsonValues, templateId) {
        this.formId = formid;
        this.templateJsonValues = templateJsonValues;
        this.SetSurveyData(formid, templateJsonValues, templateId);
        window.scrollTo(0, 0);
    }

    checkValidation(): boolean {
        if (this.surveyModel.hasErrors()) {
            return false;
        }
        else {          
            return true;
        }
    }

    saveadditionalfields(): void {
       
      this.surveyModel.doComplete();
         
       // this.surveyModel.completeLastPage();

    }
    saveasdraft(): void {        
            this.surveyModel.doComplete();
    }
 
    assignvalues(event) {
        console.log('assignvalues');
        this.surveyModel = new Survey.Model(this.json);
        this.surveyModel.data = event;
        this.surveyModel.render("surveyElement");    
       
        console.log(event);
        console.log('after surveyrender');
        this.cd.detectChanges();
        //this.surveyModel = new Survey.Model(this.json);
        //  this.surveyModel.loadState(event);

    }
    //surveyValueChanged(sender, options): void {
    //    //var result = options.name;

    //    var surveydata = sender.data;
    //}

}
