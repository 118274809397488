import { AbpHttpInterceptor } from '@abp/abpHttpInterceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import * as ApiServiceProxies from './service-proxies';
//import { IndividualClinicContactNoteRecordsComponent } from '../../app/reports/IndividualClinicContactNoteRecords/IndividualClinicContactNoteRecords.component';

@NgModule({
    providers: [
        ApiServiceProxies.RehabNoteRecordsServiceProxy,
        ApiServiceProxies.GroupContactNoteRecordsServiceProxy,
        ApiServiceProxies.IndividualClinicalNoteRecordsServiceProxy,
        ApiServiceProxies.IndividualNotesServiceProxy,        
        ApiServiceProxies.RehabNotesServiceProxy,        
        ApiServiceProxies.GroupContactNotesServiceProxy,        
        ApiServiceProxies.ICCN_GoalsServiceProxy,        
        ApiServiceProxies.ICCN_BillsServiceProxy,        
        ApiServiceProxies.Status_Of_AssignmentsServiceProxy,        
        ApiServiceProxies.ICCN_NotesServiceProxy,        
        ApiServiceProxies.ICCN_RISKsServiceProxy,        
        ApiServiceProxies.ICCN_MSEsServiceProxy,        
        ApiServiceProxies.IndividualClinicalNotesServiceProxy,        
        ApiServiceProxies.QuickLinksServiceProxy,
        ApiServiceProxies.StickyNotesServiceProxy,        
        ApiServiceProxies.ClientNotesServiceProxy,        
        ApiServiceProxies.MedMedicationsServiceProxy,
        ApiServiceProxies.ExtensionFieldServiceProxy,
        ApiServiceProxies.VitalsServiceProxy,        
        ApiServiceProxies.PsycEvalsesServiceProxy,        
        ApiServiceProxies.RehabProgrssNoteRecordsServiceProxy,
        ApiServiceProxies.MonthlyProgrssNotesServiceProxy,        
        ApiServiceProxies.RehabProgrssNotesServiceProxy,        
        ApiServiceProxies.ServicePlanRecordsServiceProxy,
        ApiServiceProxies.TCCPClientRecordsServiceProxy,
        ApiServiceProxies.MipClientRecordsServiceProxy,
        ApiServiceProxies.PSPClientRecordsServiceProxy,
        ApiServiceProxies.DischargeSummaryClientRecordsServiceProxy,
        ApiServiceProxies.HHAClientRecordsServiceProxy,
        ApiServiceProxies.IntakeClientRecordsServiceProxy,
        ApiServiceProxies.FollowupNotesServiceProxy,        
        ApiServiceProxies.ClientRecordsServiceProxy,        
        ApiServiceProxies.CommonComponentServiceProxy,
        ApiServiceProxies.TCCPTransitionsServiceProxy,        
        ApiServiceProxies.PainAssessmentScreeningsServiceProxy,        
        ApiServiceProxies.RefSourceForTccpsServiceProxy,        
        ApiServiceProxies.SystemAreaFieldValuesServiceProxy,        
        ApiServiceProxies.SystemAreaCommonDatasServiceProxy,        
        ApiServiceProxies.TransitionCarePlansServiceProxy,        
        ApiServiceProxies.PreviousTreatmentsServiceProxy,        
        ApiServiceProxies.RecommendationsServiceProxy,        
        ApiServiceProxies.HealthGoalsServiceProxy,        
        ApiServiceProxies.OutComeObjectsServiceProxy,        
        ApiServiceProxies.AcknowledgementsServiceProxy,        
        ApiServiceProxies.DischargeSummariesServiceProxy,        
        ApiServiceProxies.HealthHomeSystemAreasServiceProxy,        
        ApiServiceProxies.SignatureModulesServiceProxy,        
        ApiServiceProxies.HealthHomeSummariesServiceProxy,        
        ApiServiceProxies.MIPsServiceProxy,        
        ApiServiceProxies.IntakeHospitalizationsServiceProxy,        
        ApiServiceProxies.IntakeclientcontactsServiceProxy,        
        ApiServiceProxies.IntakeAllergiesServiceProxy,        
        ApiServiceProxies.HealthHomesServiceProxy,        
        ApiServiceProxies.PersonalSafetyPlansServiceProxy,        
        ApiServiceProxies.TreatmentPlansServiceProxy,        
        ApiServiceProxies.TreatmentDiagnosisesServiceProxy,        
        ApiServiceProxies.DischargePlansServiceProxy,        
        ApiServiceProxies.TransitionPlansServiceProxy,        
        ApiServiceProxies.SupportServicesServiceProxy,        
        ApiServiceProxies.TreatmentModesServiceProxy,        
        ApiServiceProxies.DlaSupplementalsServiceProxy,        
        ApiServiceProxies.ShortGoalsServiceProxy,        
        ApiServiceProxies.LongGoalsServiceProxy,        
        ApiServiceProxies.DLAsServiceProxy,        
        ApiServiceProxies.HealthStatusesServiceProxy,        
        ApiServiceProxies.FamilyHealthHistoriesServiceProxy,        
        ApiServiceProxies.ImmunizationsServiceProxy,        
        ApiServiceProxies.VitalSignsServiceProxy,        
        ApiServiceProxies.SubstanceUsesServiceProxy,        
        ApiServiceProxies.RecentExaminationsServiceProxy,        
        ApiServiceProxies.HealthPsychiatricsServiceProxy,        
        ApiServiceProxies.IntakeSocialDiagnosisesServiceProxy,        
        ApiServiceProxies.IntakeDiagnosesesServiceProxy,        
        ApiServiceProxies.ClientAllergiesServiceProxy,        
        ApiServiceProxies.IntakeMedicationListsServiceProxy,        
        ApiServiceProxies.MedicationMastersServiceProxy,        
        ApiServiceProxies.ClientOrientationListsServiceProxy,        
        ApiServiceProxies.ClientPractitionersServiceProxy,        
        ApiServiceProxies.IntakeEefDetailsServiceProxy,        
        ApiServiceProxies.PsychSkillDeficitsServiceProxy,        
        ApiServiceProxies.FamilyProcreationsServiceProxy,        
        ApiServiceProxies.FamilyOriginsServiceProxy,        
        ApiServiceProxies.LegalchargesServiceProxy,        
        ApiServiceProxies.SomaticDruglistsServiceProxy,        
        ApiServiceProxies.PsychViolentBehaviorsServiceProxy,        
        ApiServiceProxies.PsychiatricSuicidalsServiceProxy,        
        ApiServiceProxies.IntakeSymptomsServiceProxy,        
        ApiServiceProxies.ProgramEnrollmentsServiceProxy,        
           
        ApiServiceProxies.MedicationsServiceProxy,        
        ApiServiceProxies.DsmvcodesServiceProxy,        
        ApiServiceProxies.BehavioralDiagnosesesServiceProxy,        
        ApiServiceProxies.ClientSocialDiagnosisesServiceProxy,        
        ApiServiceProxies.WhodidyoutellMastersServiceProxy,        
        ApiServiceProxies.TreatmentsiteMastersServiceProxy,        
        ApiServiceProxies.SucidalMastersServiceProxy,        
        ApiServiceProxies.SomaticDirectiveMastersServiceProxy,        
        ApiServiceProxies.ResidentialstatusMastersServiceProxy,        
        ApiServiceProxies.QARatingMastersServiceProxy,        
        ApiServiceProxies.PsychiatricDirectiveMastersServiceProxy,        
        ApiServiceProxies.PreAdmissionMastersServiceProxy,        
        ApiServiceProxies.InterviewLocationMastersServiceProxy,        
        ApiServiceProxies.InterviewerMastersServiceProxy,        
        ApiServiceProxies.InterventionMastersServiceProxy,        
        ApiServiceProxies.FrequentThoughtMastersServiceProxy,        
        ApiServiceProxies.FireSettingMastersServiceProxy,        
        ApiServiceProxies.DomesticViolenceMastersServiceProxy,        
        ApiServiceProxies.DiabeticMastersServiceProxy,        
        ApiServiceProxies.CustodyArrangementMastersServiceProxy,        
        ApiServiceProxies.ClinicalApprovalMastersServiceProxy,        
        ApiServiceProxies.AdminApprovalMastersServiceProxy,        
        ApiServiceProxies.AbuseAnimalMastersServiceProxy,        
        ApiServiceProxies.IntakeClientsServiceProxy,        
        ApiServiceProxies.ApprovalsServiceProxy,        
        ApiServiceProxies.RiskServicesServiceProxy,        
        ApiServiceProxies.MsesServiceProxy,        
        ApiServiceProxies.CraftsServiceProxy,        
        ApiServiceProxies.DastsServiceProxy,        
        ApiServiceProxies.CagesServiceProxy,        
        ApiServiceProxies.PsychosocialAssessmentsServiceProxy,        
        ApiServiceProxies.LegalAgenciesServiceProxy,        
        ApiServiceProxies.SomaticIssuesServiceProxy,        
        ApiServiceProxies.PsychiatricSymptomsServiceProxy,        
        ApiServiceProxies.IntakeMedicationsServiceProxy,        
        ApiServiceProxies.HousingsServiceProxy,        
        ApiServiceProxies.ReferralInfosServiceProxy,        
        ApiServiceProxies.EthnicitiesServiceProxy,        
        ApiServiceProxies.SexualOrientationsServiceProxy,        
        ApiServiceProxies.EyeColorsServiceProxy,        
        ApiServiceProxies.HairColorsServiceProxy,        
        ApiServiceProxies.TransportationModesServiceProxy,        
        ApiServiceProxies.ReligiousaffilationsServiceProxy,        
        ApiServiceProxies.ProviderTypesServiceProxy,        
        ApiServiceProxies.PhychiatricsServiceProxy,        
        ApiServiceProxies.ProbationStatusesServiceProxy,        
        ApiServiceProxies.LocationTypesServiceProxy,        
        ApiServiceProxies.AuthorizationlevelsServiceProxy,        
        ApiServiceProxies.TreatmentSitesServiceProxy,        
        ApiServiceProxies.AgenciesServiceProxy,        
        ApiServiceProxies.AdvanceDirectivesesServiceProxy,        
        ApiServiceProxies.IncomeSourcesServiceProxy,        
        ApiServiceProxies.EmploymentStatusesServiceProxy,        
        ApiServiceProxies.EducationTypesServiceProxy,        
        ApiServiceProxies.ClientHealthhistoriesServiceProxy,        
        ApiServiceProxies.SerutilizationsServiceProxy,        
        ApiServiceProxies.MiscinfosServiceProxy,        
        ApiServiceProxies.HospitalizationsServiceProxy,        
        ApiServiceProxies.EefdetailsServiceProxy,        
        ApiServiceProxies.ClientprovidersServiceProxy,        
        ApiServiceProxies.ClientpoliciesServiceProxy,        
        ApiServiceProxies.ClientcontactsServiceProxy,        
        ApiServiceProxies.ClientsServiceProxy,        
        ApiServiceProxies.ServiceUtilizationsServiceProxy,        
        ApiServiceProxies.HealthHistoriesServiceProxy,        
        ApiServiceProxies.EducationEmployementDetailsServiceProxy,        
        ApiServiceProxies.PatientsServiceProxy,        
        ApiServiceProxies.FrequenciesServiceProxy,        
        ApiServiceProxies.DoseunitsServiceProxy,        
        ApiServiceProxies.SeveritiesServiceProxy,        
        ApiServiceProxies.RelationshipsServiceProxy,        
        ApiServiceProxies.HealthCareServicesServiceProxy,     
        ApiServiceProxies.LocationsServiceProxy,
        ApiServiceProxies.CommonServiceProxy,
        ApiServiceProxies.ReferralAllergiesServiceProxy,        
        ApiServiceProxies.ReferralMedicationsServiceProxy,        
        ApiServiceProxies.ProvidersServiceProxy,        
        ApiServiceProxies.SpecialitiesServiceProxy,        
        ApiServiceProxies.ReferralSourcesServiceProxy,        
        ApiServiceProxies.ReferralPointsServiceProxy,        
        ApiServiceProxies.ReferralNotesServiceProxy,        
        ApiServiceProxies.ReferralPayersServiceProxy,        
        ApiServiceProxies.PayersServiceProxy,        
        ApiServiceProxies.PlantypesServiceProxy,        
        ApiServiceProxies.DocumentsServiceProxy,        
        ApiServiceProxies.DocumenttypesServiceProxy,        
        ApiServiceProxies.ModulesServiceProxy,        
        ApiServiceProxies.ReferralContactsServiceProxy,
        ApiServiceProxies.GendersServiceProxy,
        ApiServiceProxies.MaritalStatusesServiceProxy,
        ApiServiceProxies.RacesServiceProxy,
        ApiServiceProxies.UserLanguagesServiceProxy,
        ApiServiceProxies.ReferralsServiceProxy,
        ApiServiceProxies.OrganizationsServiceProxy,        
        ApiServiceProxies.OrganizationTypesServiceProxy,        
        ApiServiceProxies.CountiesServiceProxy,        
        ApiServiceProxies.StatesServiceProxy,        
        ApiServiceProxies.CountriesServiceProxy,        
        ApiServiceProxies.AuditLogServiceProxy,
        ApiServiceProxies.CachingServiceProxy,
        ApiServiceProxies.ChatServiceProxy,
        ApiServiceProxies.CommonLookupServiceProxy,
        ApiServiceProxies.EditionServiceProxy,
        ApiServiceProxies.FriendshipServiceProxy,
        ApiServiceProxies.HostSettingsServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.LanguageServiceProxy,
        ApiServiceProxies.NotificationServiceProxy,
        ApiServiceProxies.OrganizationUnitServiceProxy,
        ApiServiceProxies.PermissionServiceProxy,
        ApiServiceProxies.ProfileServiceProxy,
        ApiServiceProxies.RoleServiceProxy,
        ApiServiceProxies.SessionServiceProxy,
        ApiServiceProxies.TenantServiceProxy,
        ApiServiceProxies.TenantDashboardServiceProxy,
        ApiServiceProxies.TenantSettingsServiceProxy,
        ApiServiceProxies.TimingServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.UserLinkServiceProxy,
        ApiServiceProxies.UserLoginServiceProxy,
        ApiServiceProxies.WebLogServiceProxy,
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.TokenAuthServiceProxy,
        ApiServiceProxies.TenantRegistrationServiceProxy,
        ApiServiceProxies.HostDashboardServiceProxy,
        ApiServiceProxies.PaymentServiceProxy,
        ApiServiceProxies.DemoUiComponentsServiceProxy,
        ApiServiceProxies.InvoiceServiceProxy,
        ApiServiceProxies.SubscriptionServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.UiCustomizationSettingsServiceProxy,
        ApiServiceProxies.PayPalPaymentServiceProxy,
        ApiServiceProxies.StripePaymentServiceProxy,
        ApiServiceProxies.Individual_Masters_ValuesServiceProxy,
        { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true }
    ]
})
export class ServiceProxyModule { }
