export class AppConsts {

    static readonly tenancyNamePlaceHolderInUrl = '{TENANCY_NAME}';

    static remoteServiceBaseUrl: string;
    static remoteServiceBaseUrlFormat: string;
    static appBaseUrl: string;
    static appBaseHref: string; // returns angular's base-href parameter value if used during the publish
    static appBaseUrlFormat: string;
    static recaptchaSiteKey: string;
    static subscriptionExpireNootifyDayCount: number;
    static intakeFormId: number = 1;
    static mipFormId: number = 2;
    static personalsafetyFormId: number = 3;
    static dischargeSummaryFormId: number = 6;
    static transitionCarePlanFormId: number = 7;
    static localeMappings: any = [];

    static percycle= "PerCycle";
    static reviewOfDoc= "ReviewOfDocumentation";
    static notAssessedReason = "NotAssessedReason";
    static ratings = "Rating";

    static healthHomeAssessmentFormId: number = 4;
    static servicePlanFormId: number = 5;
    static readonly userManagement = {
        defaultAdminUserName: 'admin'
    };

    static readonly localization = {
        defaultLocalizationSourceName: 'Bit2EHR'
    };

    static readonly authorization = {
        encrptedAuthTokenName: 'enc_auth_token'
    };

    static readonly grid = {
        defaultPageSize: 10
    };

    static myT :string;
   
}

