import { Component, ViewChild, Injector, Output, EventEmitter, CUSTOM_ELEMENTS_SCHEMA,NgModule} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { finalize } from 'rxjs/operators';
import { SignatureModulesServiceProxy, CreateOrEditSignatureModuleDto } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
import * as moment from 'moment';


@Component({
    selector: 'createOrEditSignatureModuleModal',
    templateUrl: './create-or-edit-signatureModule-modal.component.html'
})
export class CreateOrEditSignatureModuleModalComponent extends AppComponentBase {

    @ViewChild('createOrEditModal') modal: ModalDirective;


    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

    active = false;
    saving = false;

    signatureModule: CreateOrEditSignatureModuleDto = new CreateOrEditSignatureModuleDto();

            signDate: Date;


    constructor(
        injector: Injector,
        private _signatureModulesServiceProxy: SignatureModulesServiceProxy
    ) {
        super(injector);
    }

    show(signatureModuleId?: number): void {
        
this.signDate = null;

        if (!signatureModuleId) {
            this.signatureModule = new CreateOrEditSignatureModuleDto();
            this.signatureModule.id = signatureModuleId;

            this.active = true;
            this.modal.show();
        } else {
            this._signatureModulesServiceProxy.getSignatureModuleForEdit(signatureModuleId).subscribe(result => {
                this.signatureModule = result.signatureModule;

                if (this.signatureModule.signDate) {
					this.signDate = this.signatureModule.signDate.toDate();
                }

                this.active = true;
                this.modal.show();
            });
        }
    }

    save(): void {
            this.saving = true;

			
        if (this.signDate) {
            if (!this.signatureModule.signDate) {
                this.signatureModule.signDate = moment(this.signDate).startOf('day');
            }
            else {
                this.signatureModule.signDate = moment(this.signDate);
            }
        }
        else {
            this.signatureModule.signDate = null;
        }
            this._signatureModulesServiceProxy.createOrEdit(this.signatureModule)
             .pipe(finalize(() => { this.saving = false;}))
             .subscribe(() => {
                this.notify.info(this.l('SavedSuccessfully'));
                this.close();
                this.modalSave.emit(null);
             });
    }







    close(): void {

        this.active = false;
        this.modal.hide();
    }
}
