import { Component, ViewChild, Injector, Output, EventEmitter } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { finalize } from 'rxjs/operators';
import { TransitionCarePlansServiceProxy, CreateOrEditTransitionCarePlanDto, CreateOrEditClientDto, ClientsServiceProxy, CommonServiceProxy, NameValueOfString, CreateOrEditClientpolicyDto, TreatmentPlansServiceProxy, CreateOrEditTreatmentModeDto, CreateOrEditSupportServiceDto, CreateOrEditTreatmentPlanDto, CreateOrEditRefSourceForTccpDto, CreateOrEditProgramEnrollmentDto, CreateOrEditTCCPTransitionDto, NameValueDto, CreateOrEditAcknowledgementDto, CreateOrEditMedicationDto, CreateOrEditClientproviderDto, CreateOrEditTreatmentPlanExtensionDto, CreateOrEditTransitionCareExtensionDto, ExtensionFieldServiceProxy } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
import * as moment from 'moment';
import { AutoComplete, SelectItem } from 'primeng/primeng';
import { CommonProviderModalComponent } from '@app/shared/common/ClientForm/CommonProvider/common-provider-modal.component';
import { commonmedicationmodalcomponent } from '@app/shared/common/ClientForm/CommonMedication/common-medication-modal.component';
import { Router, ActivatedRoute } from '@angular/router';
import { CreateOrEditRefSourceForTccpModalComponent } from '@app/main/refSourceForTccps/refSourceForTccps/create-or-edit-refSourceForTccp-modal.component';
import { CreateOrEditTCCPTransitionModalComponent } from '@app/main/tccpTransitions/tccpTransitions/create-or-edit-tccpTransition-modal.component';
import { InvalidControls, Errorlistdata } from '@app/main/intake/intakeClients/Intakeservice';
import { debug } from 'util';
import { RegisterTenantResultComponent } from 'account/register/register-tenant-result.component';
import { AppConsts } from '../../../../shared/AppConsts';
import { clientSignatureModalComponent } from '../../shared/ClientSignature/clientsignature-modal.component';
import { CreateOrEditAcknowledgementModalComponent } from '../../shared/acknowledgements/create-or-edit-acknowledgement-modal.component';
import * as _ from 'lodash';
//import { transitioncareplans } from './transitioncareplans';

import { SurveyComponent } from '../../../shared/common/SurveyJSExtensionFields/survey/survey.component';
import { FormType } from '../../../../shared/AppEnums';
import { electronicallysignedbymodalComponent } from '@app/main/common/electronically-signed-by-modal.component';

@Component({
    selector: 'createOrEditTransitionCarePlanModal',
    templateUrl: './create-or-edit-transitionCarePlan-modal.component.html'
})
export class CreateOrEditTransitionCarePlanModalComponent extends AppComponentBase {

    @ViewChild('createOrEditModal') modal: ModalDirective;
    @ViewChild('transitionCarePlanForm') form;
    @ViewChild('commonProviderModal') commonProviderModal: CommonProviderModalComponent;
    @ViewChild('commonmedicationmodal') commonmedicationmodal: commonmedicationmodalcomponent;
    @ViewChild('createOrEditRefSourceForTccpModal') createOrEditRefSourceForTccpModal: CreateOrEditRefSourceForTccpModalComponent;
    @ViewChild('createOrEditTCCPTransitionModal') createOrEditTCCPTransitionModal: CreateOrEditTCCPTransitionModalComponent;
    @ViewChild('createOrEditAcknowledgementModal') createOrEditAcknowledgementModal: CreateOrEditAcknowledgementModalComponent;
    @ViewChild('clientSignatureModal') clientSignatureModal: clientSignatureModalComponent
    @ViewChild('survey') survey: SurveyComponent;
    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('electronicallysignedbymodal') electronicallysignedbymodal:electronicallysignedbymodalComponent;
    active = false;
    saving = false;

    transitionCarePlan: CreateOrEditTransitionCarePlanDto = new CreateOrEditTransitionCarePlanDto();
    Client: CreateOrEditClientDto = new CreateOrEditClientDto();
    clientpolicylist: CreateOrEditClientpolicyDto[] = new Array();
    treatmentmodelist: CreateOrEditTreatmentModeDto[] = new Array();
    supportServicelist: CreateOrEditSupportServiceDto[] = Array();
    refSourceForTccplist: CreateOrEditRefSourceForTccpDto[] = new Array();
    treatmentPlan: CreateOrEditTreatmentPlanDto = new CreateOrEditTreatmentPlanDto();
    programenrollmentlist: CreateOrEditProgramEnrollmentDto[] = new Array();
    tccpTransition: CreateOrEditTCCPTransitionDto = new CreateOrEditTCCPTransitionDto();
    tccpTransitionlist: CreateOrEditTCCPTransitionDto[] = new Array();
    TCCPTransitionlistdata: CreateOrEditTCCPTransitionDto[] = new Array();
    intakeAcknowledgementList: CreateOrEditAcknowledgementDto[] = new Array();

    @ViewChild('autocomplete') autocompleteCharge: AutoComplete;
    @ViewChild('autocomplete1') autocompleteCharge1: AutoComplete;
    @ViewChild('autocomplete2') autocompleteCharge2: AutoComplete;
    dob: Date;
    dtAddmision: Date;
    lstDtOfService: Date;
    dtTransition: Date;
    clientname: any;
    blockedDocument: boolean = false;
    reviewtype: any;
    allClients: any[];
    dobmindate = moment('01-01-1901').toDate();
    minDate: Date;
    maxDate: Date;
    today: Date;
    practioners: any[];
    practioners1: any[];
    LocationSite: any[];
    TccpRSDDL: any[];
    allClientswithTrainers: any[];
    clienttrainername: any;
    clienttrainernamerec: any
    Recommendsource: any[];
    transitionCarePlanId: number;
    private sub: any;
    _PRP: any;
    public _isOpensp: boolean = true;
    _formsubmitted = false;
    programenrollmentlistdata: any;
    TreatmentSite: any[];
    programs: any[];
    _location = false;
    practionerTransfer: boolean = false;
    practionerreciever: boolean = false;
    public _ClientPractitionerLogin: boolean = false;
    _assigndate: any;
    _transitiondate: any;
    RecTransCoordinateName: any[];
    public Phonemask: Array<string | RegExp>
    TCCPDDLMastersMastersd: any[];
    ServicesProvidedDDL: any;
    FrequencyofTreatmentDDL: any;
    display = false;
    ClientparticipationplanDDL: any[];
    _errorlist: Array<Errorlistdata>;
    _invalidcontrols: Array<InvalidControls> = [];
    public _sppermission: boolean = false;
    parentClientID = '';
    CliSignFlag: boolean = false;
    CliSign: any;
    _InvalidDob = false;
    userName: string;
    lastModifiedUserName: string;
    modifiedDate: Date;
    _serviceCheck = false;
    clientpractioners: NameValueOfString = new NameValueOfString();
    clientpractioners1: NameValueOfString = new NameValueOfString();
    selectedItemValue: any;
    _tccpMultiselectPermission: boolean = true;
    clientParticipation;
    _checkvalidphone: boolean = true;
    _checkvalidfax: boolean = true;
    _clientparticipationCheck: boolean = false;
    selectedId: any[];
    medicationlist: CreateOrEditMedicationDto[] = new Array();
    clientproviderlist: CreateOrEditClientproviderDto[] = new Array();

    //For Surveyjs
    extensionvalue: CreateOrEditTransitionCareExtensionDto = new CreateOrEditTransitionCareExtensionDto();
    formID: any = FormType.TransitionCarePlan;
    templateId: number;
    json: any = "";

    constructor(
        injector: Injector,
        private _transitionCarePlansServiceProxy: TransitionCarePlansServiceProxy,
        private _clientsServiceProxy: ClientsServiceProxy,
        private _commonServiceProxy: CommonServiceProxy,
        private _treatmentPlansServiceProxy: TreatmentPlansServiceProxy,
        private _router: Router,
        private route: ActivatedRoute,
        //private _transitioncareplans: transitioncareplans,
        private _externalServiceProxy: ExtensionFieldServiceProxy,

    ) {
        super(injector);
        this.today = new Date();
        (moment as any).fn.toString = function () { return this.format("L"); };
        moment.locale("en");
        this.Phonemask = ['(', /[0-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

        this.sub = this.route.params.subscribe(params => {
            this.transitionCarePlanId = +params['transitionCarePlanId'];
        });
    }

    ngOnInit() {
        this.loadStimulScripts();

        // this.show(this.transitionCarePlan.id);
        this.show(this.transitionCarePlanId);

        this._commonServiceProxy.getTreatmentsites().subscribe(result => {
            this.LocationSite = result;
        });

        this._commonServiceProxy.gethealthcareservice().subscribe(result => {
            this.programs = result;
        });

        this._commonServiceProxy.getTreatmentsiteall().subscribe(result => {
            this.TreatmentSite = result;
        });

        this._commonServiceProxy.getTCCPreferralsource().subscribe(result => {
            this.TccpRSDDL = result;
            this.Recommendsource = this.TccpRSDDL.filter(s => s.id == 1);
        });

        this._commonServiceProxy.getClientPraticipation().subscribe(data => {

            this.ClientparticipationplanDDL = data;
        });

        this.createOrEditTCCPTransitionModal.show();

        this.Binddropdown();
    }

    show(transitionCarePlanId?: number): void {

        if (this.transitionCarePlanId == 0) {
            this.transitionCarePlanId = null;
        }


        this.dob = null;
        this.dtAddmision = null;
        this.lstDtOfService = null;
        this.dtTransition = null;
        this.transitionCarePlan.creatorUserId = this.appSession.userId;
        this.userName = this.appSession.user.name + " " + this.appSession.user.surname;
        this.minDate = new Date();
        this.maxDate = new Date();
        this.transitionCarePlan.dtTransition = moment(this.today);
        //this.maxDate.setDate(this.maxDate.getDate() + 30);
        this.maxDate.setDate(this.today.getDate() + 30);

        if (!transitionCarePlanId) {
            this.transitionCarePlan = new CreateOrEditTransitionCarePlanDto();
            this.transitionCarePlan.id = transitionCarePlanId;
            this.transitionCarePlan.transitionstatus = "New";

            //   this.programenrollmentlistdata = this.commonProgramEnrollmentModal.getselectedList();
            this.clientpractioners = null;
            this._tccpMultiselectPermission = true;
            this.active = true;
            this._sppermission = true;
            this._ClientPractitionerLogin = true;

            //Assigning value to survey template
            if (!this.transitionCarePlan.extensionvalues) {
                this.extensionvalue = new CreateOrEditTransitionCareExtensionDto();
                this.extensionvalue.templateJsonValues = "";
                this.templateId = 0;
                //this.survey.assignvalues(this.extensionvalue.templateJsonValues);

                //For Surveyjs
                this.survey.setFormID(this.formID, this.extensionvalue.templateJsonValues, this.templateId);
            }
            this.setTemplateID();

            // this.modal.show();
        } else {

            this._sppermission = false;
            this.clientpractioners = new NameValueDto();
            this._tccpMultiselectPermission = false;
            this._transitionCarePlansServiceProxy.getTransitionCarePlanForEdit(transitionCarePlanId).subscribe(result => {
                this.transitionCarePlan = result.transitionCarePlan;

                this.tccpTransitionlist = result.transitionCarePlan.tccpTransitioninfo;
                this._PRP = this.tccpTransitionlist.filter(s => s.program == 1 || s.program == 2 || s.program == 4);
                if (this._PRP.length > 0) {

                    this.createOrEditTCCPTransitionModal.getPRP(this._PRP)
                                         
                    this.createOrEditTCCPTransitionModal.assigndata(this.tccpTransitionlist, this.transitionCarePlan.transitionstatus);
                    console.log(this.tccpTransitionlist);                }


                //Assigning value to survey template
                if (this.transitionCarePlan.extensionvalues.length > 0) {
                    this.transitionCarePlan.extensionvalues.forEach(c => { this.extensionvalue.templateJsonValues = c.templateJsonValues, this.templateId = c.templateId });
                    
                    //For Surveyjs
                    this.survey.setFormID(this.formID, JSON.parse(this.extensionvalue.templateJsonValues), this.templateId);
                }
                else {                   
                    this.extensionvalue.templateJsonValues = "";
                    this.templateId = 0;
                    //For Surveyjs
                    this.survey.setFormID(this.formID, this.extensionvalue.templateJsonValues, this.templateId);
                    this.setTemplateID();
                }
              


                this.clientParticipation = JSON.parse(this.transitionCarePlan.clientParticipation);
                this.userName = result.userName;
                this.lastModifiedUserName = result.lastModifiedUserName;
                this.transitionCarePlan.lastModifierUserId = this.appSession.userId;
                this.modifiedDate = moment(this.transitionCarePlan.lastModificationTime).toDate();

                this.clientname = result.transitionCarePlan.clientname;

                //Report show
                this.transitionCarePlan.clientname = result.client.firstName + result.client.middleName + result.client.lastName;

                this.autocompleteCharge1.inputEL.nativeElement.value = result.transitionCarePlan.providername;
                this.transitionCarePlan.transCoordinateName = result.transitionCarePlan.transCoordinateName;
                //this.clienttrainername = this.transitionCarePlan.transCoordinateName;
                this.autocompleteCharge2.inputEL.nativeElement.value = result.transitionCarePlan.providernameRecTransCoordinate;
                this.transitionCarePlan.recTransCoordinateName = result.transitionCarePlan.recTransCoordinateName;
                //this.clienttrainernamerec = this.transitionCarePlan.recTransCoordinateName;
                this.dtTransition = null;

                this.transitionCarePlan.firstsource = result.client.educationEmployementinfo.firstSourceOfIncomeName;
                this.transitionCarePlan.firstsourceamount = result.client.educationEmployementinfo.amountPerMonth;
                this.transitionCarePlan.secondsource = result.client.educationEmployementinfo.secondSourceOfIncomeName;
                this.transitionCarePlan.secondsourceamount = result.client.educationEmployementinfo.secAmountPerMonth;
                if (this.transitionCarePlan.dob) {
                    this.dob = this.transitionCarePlan.dob.toDate();
                }
                if (this.transitionCarePlan.dtAddmision) {
                    this.dtAddmision = this.transitionCarePlan.dtAddmision.toDate();
                }

                if (this.transitionCarePlan.lstDtOfService) {
                    this.lstDtOfService = this.transitionCarePlan.lstDtOfService.toDate();
                }
                if (this.transitionCarePlan.dtTransition) {
                    this.dtTransition = this.transitionCarePlan.dtTransition.toDate();
                }

                this.refSourceForTccplist = result.transitionCarePlan.refSourceForTccpinfo;
                this.createOrEditRefSourceForTccpModal.assigndata(this.refSourceForTccplist);

                try {
                    
                    this.intakeAcknowledgementList = result.transitionCarePlan.acknowledgementInfo;
                    var visible = false;
                    this.createOrEditAcknowledgementModal.assigndata(this.intakeAcknowledgementList, visible, this.transitionCarePlan.transitionstatus);

                }
                catch{

                }

                if (this.transitionCarePlan.id && (this.transitionCarePlan.transitionstatus == "Submitted" || this.transitionCarePlan.transitionstatus == "Acknowledged")) {
                    this._sppermission = true;
                    this._tccpMultiselectPermission = true;
                    // this.blockedDocument = true;
                    //this.CheckLoginUser = true;
                    this._ClientPractitionerLogin = true;
                }

                

                //this._transitionCarePlansServiceProxy.getTransitionCarePlanclient(this.transitionCarePlan.clientID)
                //    .pipe(finalize(() => { this.blockedDocument = false; }))
                //    .subscribe(result => {
                //        
                //        this.Client.clientpolicyinfo = this.Client.clientpolicyinfo.filter(x => x.status == 1);
                //        this.clientpolicylist = this.Client.clientpolicyinfo
                //    });
                //this._clientsServiceProxy.getClientForEdit(this.transitionCarePlan.clientID)
                this._transitionCarePlansServiceProxy.onSelectedClient(this.transitionCarePlan.clientID)
                    .pipe(finalize(() => { this.blockedDocument = false; }))
                    .subscribe(result => {

                        this.Client = result.client;
                        this.Client.clientpolicyinfo = this.Client.clientpolicyinfo.filter(x => x.status == 1);
                        this.clientpolicylist = this.Client.clientpolicyinfo

                        
                        //Report show
                        if (this.transitionCarePlan.clientpolicyinfo != null)
                        this.transitionCarePlan.clientpolicyinfo = this.clientpolicylist;
                    });


                this._transitionCarePlansServiceProxy.getTreatmentPlanChildData(this.transitionCarePlan.clientID)
                    .pipe(finalize(() => { this.blockedDocument = false; }))
                    .subscribe(result => {

                        this.treatmentPlan = result;
                        this.treatmentPlan.treatmentmodeinfo = this.treatmentPlan.treatmentmodeinfo;
                        this.treatmentmodelist = this.treatmentPlan.treatmentmodeinfo
                        
                        //Report show
                        if (this.treatmentmodelist != null)
                        this.transitionCarePlan.treatmentmodeinfo = this.treatmentmodelist;

                       
                        this.treatmentPlan.supportserviceinfo = this.treatmentPlan.supportserviceinfo;
                        this.supportServicelist = this.treatmentPlan.supportserviceinfo

                         //Report show
                        if (this.supportServicelist != null)
                        this.transitionCarePlan.supportserviceinfo = this.supportServicelist;
                    });

                this.active = true;

                //this.commonProgramEnrollmentModal.getCheckedData(this.transitionCarePlan.clientID, 1);
                //this.programenrollmentlist = this.commonProgramEnrollmentModal.getCheckedData(this.transitionCarePlan.clientID, 1);

                this.commonProviderModal.show(this.transitionCarePlan.clientID);
                this.active = true;

                this.commonmedicationmodal.show(this.transitionCarePlan.clientID);
                this.active = true;

                //   this.programenrollmentlistdata = this.commonProgramEnrollmentModal.getselectedList();
                //   this.modal.show();
                this._formsubmitted = false;
            });
        }
    }


    surveyjsData(event): void {

        this.transitionCarePlan.extensionvalues = [];

        this.extensionvalue.templateJsonValues = event;
        this.extensionvalue.transitionPlanId = this.transitionCarePlanId

        if (this.extensionvalue.transitionPlanId == null)
            this.extensionvalue.transitionPlanId = 0;

        this.extensionvalue.templateId = this.templateId;

        if (!this.transitionCarePlan) {
            this.transitionCarePlan = new CreateOrEditTransitionCarePlanDto();
        }

        this.transitionCarePlan.extensionvalues.push(this.extensionvalue);



    }

    setTemplateID() {
        this._externalServiceProxy.getExtensionTemplateForEdit(this.formID)
            .pipe(finalize(() => { }))
            .subscribe(x => {
                this.templateId = x.template.id;


            });

    }


    public Binddropdown() {

        this._commonServiceProxy.getTCCPreferralsource().subscribe(result => {

            this.TCCPDDLMastersMastersd = result;
            this.ServicesProvidedDDL = this.TCCPDDLMastersMastersd.filter(s => s.tccpddlMaster.code == 2);
            this.FrequencyofTreatmentDDL = this.TCCPDDLMastersMastersd.filter(s => s.tccpddlMaster.code == 3);
            //this.ClientparticipationplanDDL = this.TCCPDDLMastersMastersd.filter(s => s.tccpddlMaster.code == 4);
        });
    }

    //Report DDL
    ReportValueOnChange(event) {
        
        var Objproprtyname = event.srcElement.id.split("_");
        var propertyName = _.camelCase(Objproprtyname[1]) + "Name";
        this.transitionCarePlan[propertyName] = event.currentTarget.selectedOptions[0].innerText;
    }

    //Report DDL
    selectedItemLabel(event, propertyName, ngmodelobj, Options) {
        

        this.selectedId = ngmodelobj;
        var Name = [];

        if (ngmodelobj != null && ngmodelobj != 'undefined') {
            for (var i = 0; i < this.selectedId.length; i++) {
                try {
                    var selectedItem = Options.filter(s => s.value == this.selectedId[i]);
                    Name.push(selectedItem[0].label);
                } catch{}
            }
            this.transitionCarePlan[propertyName] = Name.join(",");

        }
    }

    createRefSourceForTccp(): void {

        this.createOrEditRefSourceForTccpModal.show();
    }

    addtRefSourceForTccplist(event?: CreateOrEditRefSourceForTccpDto[]): void {

        this.refSourceForTccplist = [];
        this.refSourceForTccplist = event;

    }

    editRefSourceForTccp(event: CreateOrEditRefSourceForTccpDto, i: number): void {

        this.createOrEditRefSourceForTccpModal.editrefSourceForTccp(event, i);

    }

    deleteRefSourceForTccp(i: number) {

        if (i != -1) {
            this.message.confirm('Are you sure you want to delete the record?', ' ',
                (isConfirmed) => {
                    if (isConfirmed) {
                        this.refSourceForTccplist.splice(i, 1);
                    }
                }
            );
        }
    }

    protected clearedInstallation(event: any): void {

        this.clientpractioners = null;
        this.transitionCarePlan.transCoordinateName = null;
        this.transitionCarePlan.providername = null;
    }

    protected clearedInstallation1(event: any): void {

        this.clientpractioners1 = null;
        this.transitionCarePlan.recTransCoordinateName = null;
        this.transitionCarePlan.providername = null;
    }

    protected blurInstallation(event: any): void {

    }

    settreatmentsite(pid: string): any {
        if (this.TreatmentSite)
            return this.TreatmentSite.filter(x => x.value == pid);
    }

    setprograms(pid: number): any {




        if (this.programs)
            return this.programs.filter(x => x.healthCareService.id == pid);
    }

    protected selectedInstallationChanged(event: any): void {

        this.clientname = event.client.firstName;
        this.transitionCarePlan.clientID = event.client.clientID;
        this._ClientPractitionerLogin = false;
        //
        //this.autocompleteCharge.inputEL.nativeElement.value = event.name
        //this.clientname = this.autocompleteCharge.inputEL.nativeElement.value;

        if (this.transitionCarePlan.clientID) {
            this._tccpMultiselectPermission = false;
            this._transitionCarePlansServiceProxy.getTransitionCarePlanclient(this.transitionCarePlan.clientID)
                .pipe(finalize(() => { this.blockedDocument = false; }))
                .subscribe(result => {

                    this.Client = result.client;
                    this.transitionCarePlan.dob = result.client.dob;
                    this.dob = this.transitionCarePlan.dob.toDate();
                    // this.transitionCarePlan.firstSourceOfIncome = result.client.educationEmployementinfo.firstSourceOfIncome;
                    this.transitionCarePlan.reasonforAdmision = result.reason;
                    this.transitionCarePlan.clientStrength = result.clientStrength;
                    this.transitionCarePlan.clientNeed = result.clientNeed;

                    //Report show
                    this.transitionCarePlan.clientname = result.client.firstName + result.client.middleName + result.client.lastName;

                    this.transitionCarePlan.firstsource = result.client.educationEmployementinfo.firstSourceOfIncomeName;
                    this.transitionCarePlan.firstsourceamount = result.client.educationEmployementinfo.amountPerMonth;
                    this.transitionCarePlan.secondsource = result.client.educationEmployementinfo.secondSourceOfIncomeName;
                    this.transitionCarePlan.secondsourceamount = result.client.educationEmployementinfo.secAmountPerMonth;
                    
                    this.programenrollmentlist = result.client.programEnrollmentInfo;
                    this.Client.clientpolicyinfo = this.Client.clientpolicyinfo.filter(x => x.status == 1);
                    this.clientpolicylist = this.Client.clientpolicyinfo

                    
                    //Report show
                    if (this.transitionCarePlan.clientpolicyinfo != null)
                    this.transitionCarePlan.clientpolicyinfo = this.clientpolicylist;
                    
                    this._PRP = this.programenrollmentlist.filter(s => s.program == 1 || s.program == 2 || s.program == 4);
                    if (this._PRP.length > 0) {

                        this.createOrEditTCCPTransitionModal.getPRP(this._PRP);

                        this.createOrEditTCCPTransitionModal.getprogramenrollmentlistgrid(this.transitionCarePlan.clientID);
                    }

                    this.transitionCarePlan.legacyID = this.Client.legacyID;

                    if (this.transitionCarePlan.clientID) {

                        this._sppermission = false;
                    }
                });
        }

        this._transitionCarePlansServiceProxy.getTreatmentPlanChildData(this.transitionCarePlan.clientID)
            .pipe(finalize(() => { this.blockedDocument = false; }))
            .subscribe(result => {

                this.treatmentPlan = result;
                this.treatmentPlan.treatmentmodeinfo = this.treatmentPlan.treatmentmodeinfo;
                this.treatmentmodelist = this.treatmentPlan.treatmentmodeinfo
                
                //Report show
                if (this.treatmentmodelist != null)
                this.transitionCarePlan.treatmentmodeinfo = this.treatmentmodelist;

                this.treatmentPlan.supportserviceinfo = this.treatmentPlan.supportserviceinfo;
                this.supportServicelist = this.treatmentPlan.supportserviceinfo

                //Report show
                if (this.supportServicelist != null)
                this.transitionCarePlan.supportserviceinfo = this.supportServicelist;
            });

        this.commonProviderModal.show(this.transitionCarePlan.clientID);

        this.commonmedicationmodal.show(this.transitionCarePlan.clientID);

        var visible = false;
        this.createOrEditAcknowledgementModal.assigndata(this.intakeAcknowledgementList, visible, null);
        this.blockedDocument = false;
    }

    onSearchChange(event) {
        var searchValue = event.target.value.trim();
        if (!searchValue.length) {
            (<HTMLInputElement>document.getElementById(event.target.id)).value = '';
        }
    }

    programenrollmentlistd(event?: CreateOrEditProgramEnrollmentDto[]): void {

        //   this.programenrollmentlistdata = [];
        this.programenrollmentlist = event;
    }

    AddTccptransitiontlist(event?: CreateOrEditTCCPTransitionDto[]): void {
        
        //  this.TCCPTransitionlistdata = [];

        this.transitionCarePlan.tccpTransitioninfo = event;
       
    }

    Onphonefaxcheck() {

        if (this.transitionCarePlan.pharmacyPhone) {
            this._checkvalidphone = false;
            this._checkvalidphone = this.checkvalidphone(this.transitionCarePlan.pharmacyPhone);
            if (!this._checkvalidphone) {
                this._checkvalidphone = false;
            }
            else {
                this._checkvalidphone = true;
            }
        }
        else
            this._checkvalidphone = true;

        if (this.transitionCarePlan.fax) {
            this._checkvalidfax = false;
            this._checkvalidfax = this.checkvalidphone(this.transitionCarePlan.fax);
            if (!this._checkvalidfax) {
                this._checkvalidfax = false;
            }
            else {
                this._checkvalidfax = true;
            }
        }
        else
            this._checkvalidfax = true;
    }

    public checkvalidphone(phone: string): boolean {
        let _isvalid = true;
        if (phone) {
            phone = phone.replace('(', '').replace(')', '').replace(' ', '').replace('-', '');
            if (phone.includes('_')) {
                var newStr = phone.replace(/_/g, "");
                if (newStr.length < 10)
                    _isvalid = false;
                return _isvalid;
            }
            else {
                if (phone.length < 10)
                    _isvalid = false;
                return _isvalid;
            }
        }
        _isvalid = false;
        return _isvalid;
    }

    getselectedList() {
        
        if (this.programenrollmentlist) {

            this.programenrollmentlistdata = this.programenrollmentlist.filter(x => x.status == 1 && x.programChack == true);
            for (let tccp of this.programenrollmentlistdata) {
                
                let tccpTransition = new CreateOrEditTCCPTransitionDto();
                this.tccpTransition.treatmentsite = tccp.treatmentSite;
                this.tccpTransition.program = tccp.program;
                this.tccpTransition.enrollmentDate = tccp.enrollmentDate;
                this.tccpTransition.selectLevel = tccp.selectLevel;
                this.tccpTransition.location = tccp.location;
                this.tccpTransition.id = null;
                tccpTransition.init(this.tccpTransition);

                this.tccpTransitionlist.push(tccpTransition);
                // tccpTransition.treatmentsite=this.programenrollmentlistdata.treatmentSite;
            }
            // this.modalSave.emit(this.newprogramenrollmentlist);
            //this.tccpTransition.treatmentsite = this.programenrollmentlistdata.treatmentsite;
        }
    }

    //search(event) {       
    //    var _data = event.query.trim();
    //    if (_data) {
    //        this._commonServiceProxy.getAllclients(event.query).subscribe(data => {
    //            this.allClients = data;
    //        });
    //    }       
    //}

    clearedname(event) {
        this.clientname = null;
        this.transitionCarePlan.clientID = null;
        this.Client.dob = null;
        this.createOrEditTCCPTransitionModal.clearPrograms();
    }

    search(event) {
        var _data = event.query.trim();
        if (_data) {
            this._commonServiceProxy.getclients(event.query).subscribe(data => {

                this.allClients = data;
            });
        }
    }

    search1(event) {
        var _data = event.query.trim();
        if (_data) {
            this._commonServiceProxy.getclientpractioners(event.query).subscribe(data => {
                this.practioners = data;
            });
        }
    }
    search2(event) {

        var _data = event.query.trim();
        if (_data) {
            this._commonServiceProxy.getclientpractioners(event.query).subscribe(data => {
                this.practioners1 = data;
            });
        }
    }
    async save(buttonType) {
        this.transitionCarePlan.acknowledgementInfo = this.intakeAcknowledgementList;
        // this.saving = true;
        if (buttonType === "save" || buttonType === "validate") {

            //this._location = true;
            this._formsubmitted = true;
            if (this.transitionCarePlan.locationID < 1 || this.transitionCarePlan.locationID == undefined) {
                this._location = true;
                //return false;
            }
            else {
                this._location = false;
            }

            this.survey.surveydisplayaccordian();

            if (this.transitionCarePlan.lstDtOfService) {
                if (this.transitionCarePlan.lstDtOfService.toString() == 'Invalid Date') {
                    this.transitionCarePlan.lstDtOfService == null;
                   // return false;
                }
            }


            if (this.transitionCarePlan.dtTransition) {
                if (this.transitionCarePlan.dtTransition.toString() == 'Invalid Date') {
                    this.transitionCarePlan.dtTransition == null;
                    //return false;
                }
            }

            if (!this.transitionCarePlan.acknowledgementInfo)
                this.transitionCarePlan.acknowledgementInfo = [];
            if (this.transitionCarePlan.acknowledgementInfo.length == 0) {
                this.createOrEditAcknowledgementModal.showError(true);
                return false;
            }
            else {
                var tempack = this.transitionCarePlan.acknowledgementInfo.filter(s => s.userType == 1);
                if (tempack.length < 1) {
                    this.createOrEditAcknowledgementModal.showError(true);
                    return false;
                }
                else {
                    this.createOrEditAcknowledgementModal.showError(false);
                }
                
            }

            
            await this.createOrEditTCCPTransitionModal.save();
            var checkprog = false;
            if (this.transitionCarePlan.tccpTransitioninfo) {
                checkprog = this.transitionCarePlan.tccpTransitioninfo.every(x => x.status == false || x.status == undefined || x.status == null);
                if (checkprog) {
                    this.createOrEditTCCPTransitionModal.showError(true);
                    this.blockedDocument = false;
                    return false
                }
            }

            await this.practionerValidation();

            if (this.practionerTransfer == true || this.practionerreciever == true)
                return false;


            if (!this.survey.checkValidation()) {
                this.blockedDocument = false;
                return false;
            }

            if (buttonType === "save") {
             
                    this.blockedDocument = false;
                    this.clientSignatureModal.show(this.clientname, this.transitionCarePlanId, null);
             


            }
            
            




            if (this.form.valid === false) {
                this.scrollIfFormHasErrors(this.form).then(() => {
                    
                    // Run any additional functionality if you need to.
                    //this._invalidcontrols = [];
                    //const controls = this.form.controls;
                    //for (const name in controls) {
                    //    let _invlidobj = new InvalidControls();
                    //    _invlidobj.name = name;
                    //    this._invalidcontrols.push(_invlidobj)
                    //    console.log(_invlidobj.name);
                    //}
                });

                return false;
            }
            //this.transitionCarePlan.refSourceForTccpinfo = this.refSourceForTccplist;
            //await this.createOrEditTCCPTransitionModal.save();

            //this._errorlist = [];
            //this._invalidcontrols = await this._transitioncareplans.seterrordata(this.transitionCarePlan);

            //this.formsavedata();
            this.transitionCarePlan.refSourceForTccpinfo = this.refSourceForTccplist;

            this.transitionCarePlan.clientParticipation = JSON.stringify(this.clientParticipation);
            this.transitionCarePlan.transitionstatus = "Valid";

            this.transitionCarePlan.acknowledgementInfo = this.intakeAcknowledgementList;
            this.transitionCarePlan = this.setIdnull(this.transitionCarePlan);
            //if (buttonType === "save") {
            //    if (!this.transitionCarePlan.acknowledgementInfo)
            //        this.transitionCarePlan.acknowledgementInfo = [];
            //    if (this.transitionCarePlan.acknowledgementInfo.length == 0) {
            //        this.createOrEditAcknowledgementModal.showError(true);
            //        return false;
            //    }
            //    else {
            //        this.createOrEditAcknowledgementModal.showError(false);
            //    }

            //    this.blockedDocument = false;
            //    this.clientSignatureModal.show(this.clientname, this.transitionCarePlanId, null);

               
            //}
            if (buttonType === "validate") {
                this.blockedDocument = true;
                this.saveIntoDB(this.transitionCarePlan.transitionstatus);
            }
            //
            //this._errorlist = await this._transitioncareplans.getIntersect(this._transitioncareplans.errordata, this._invalidcontrols);
            //
            //if (this._errorlist.length > 0) {
            //    this.display = true;
            //    return false;
            //}
            //this.saveIntoDB(this.transitionCarePlan.transitionstatus);
        }
        
        if (buttonType === "draft") {

            this._formsubmitted = false;

            this._location = true;


            if (this.transitionCarePlan.locationID < 1 || this.transitionCarePlan.locationID == undefined) {
                this._location = true;
                return false;
            }
            else {
                this._location = false;
            }

            if (this.transitionCarePlan.lstDtOfService) {
                if (this.transitionCarePlan.lstDtOfService.toString() == 'Invalid Date') {
                    this.transitionCarePlan.lstDtOfService == null;
                    return false;
                }
            }


            if (this.transitionCarePlan.dtTransition) {
                if (this.transitionCarePlan.dtTransition.toString() == 'Invalid Date') {
                    this.transitionCarePlan.dtTransition == null;
                    return false;
                }
            }

            //this._errorlist = [];
            //this._invalidcontrols = await this._treatmentservices.seterrordata(this.treatmentPlan);
            
            this.formsavedata();
            this.blockedDocument = true;
            this.transitionCarePlan.clientParticipation = JSON.stringify(this.clientParticipation);
            this.transitionCarePlan.transitionstatus = "Invalid";
            this.transitionCarePlan = this.setIdnull(this.transitionCarePlan);

            // this.transitionCarePlan.transitionstatus = "Valid";
            this.transitionCarePlan.acknowledgementInfo = this.intakeAcknowledgementList;

            // this.transitionCarePlan.transitionstatus = status;
            this.survey.saveadditionalfields();
            this._transitionCarePlansServiceProxy.createOrEdit(this.transitionCarePlan)
                .pipe(finalize(() => { this.saving = false; this.blockedDocument = false; }))
                .subscribe(() => {
                    this.setcanDeactivate(true);
                    this.notify.info(this.l('SavedSuccessfully'));
                    this._router.navigate(['app/main/transitionCarePlans/transitionCarePlans']);
                });
        }
    }

    async formsavedata() {
        

        this.transitionCarePlan.refSourceForTccpinfo = this.refSourceForTccplist;
        //this.transitionCarePlan.tccpTransitioninfo = this.tccpTransitionlist;
        await this.createOrEditTCCPTransitionModal.save();
    }

    public setIdnull(transitionCarePlan) {
        this.transitionCarePlan = transitionCarePlan;

        if (this.transitionCarePlan.id == 0 || this.transitionCarePlan.id == undefined)
            this.transitionCarePlan.id = null;

        this.transitionCarePlan.refSourceForTccpinfo.forEach(s => s.id = null);

        if (this.transitionCarePlan.tccpTransitioninfo != null)
            this.transitionCarePlan.tccpTransitioninfo.forEach(s => s.id = null);

        return this.transitionCarePlan;
    }

    printlink(btntype) {
        var clickButton;
        if (btntype == 'p') {
            this.setcanDeactivate(true);
            if (this.transitionCarePlanId)
                this._router.navigate(['app/reports/TCCPClientRecords/TCCPClientRecords', this.transitionCarePlanId, { previousUrl: 'app/main/transitionCarePlans/create-or-edit-transitionCarePlan-modal/' + this.transitionCarePlanId }]);
            else {
                this.message.warn('Hey! You should save the client information before trying to print it.', ' ');
            }
        }
    }

    async saveIntoDB(status) {

        this.setcanDeactivate(true);
        this.transitionCarePlan.clientSignature = btoa(this.CliSign);
        this.transitionCarePlan.signatureFlag = this.CliSignFlag;
       
        //this.transitionCarePlan = this.setIdnull(this.transitionCarePlan);

        // this.transitionCarePlan.transitionstatus = "Valid";
       // this.transitionCarePlan.transitionstatus = status;

        this.survey.saveadditionalfields();
        this._transitionCarePlansServiceProxy.createOrEdit(this.transitionCarePlan)
            .pipe(finalize(() => { this.saving = false; this.blockedDocument = false; }))
            .subscribe(() => {
                this.notify.info(this.l('SavedSuccessfully'));
                this._router.navigate(['app/main/transitionCarePlans/transitionCarePlans']);
                this.close();
            });

    }

    async scrollIfFormHasErrors(form): Promise<any> {
        await form.invalid;
        this.scrollToError();
    }
    scrollToError(): void {
        const firstElementWithError = document.querySelector('.ng-invalid');
        this.scrollTo(firstElementWithError);
    }
    scrollTo(el: Element): void {
        if (el) {
            el.scrollIntoView({ behavior: 'smooth' });
        }
    }

    close(): void {

        this.active = false;
        this._router.navigate(['app/main/transitionCarePlans/transitionCarePlans']);
        //  this.modal.hide();
    }

    searchdata(event) {

        var _data = event.query.trim();
        if (_data) {
            this._commonServiceProxy.getclientpractionerswithtrainer(event.query).subscribe(data => {

                this.allClientswithTrainers = data;
            });
        }
    }

    onSiteChanged(event) {
        
        this.transitionCarePlan.locationIDName = event.currentTarget.selectedOptions[0].innerText;

        if (this.transitionCarePlan.locationID)
            this._location = false;
        else
            this._location = true;
    }

    protected selectedInstallationChangedprac(event: any): void {
        
        //  this.transitionCarePlan.transCoordinateName = event.firstName;
        this.transitionCarePlan.transCoordinateREPName = event.firstName;

        this.transitionCarePlan.transCoordinateName = event.id;
        if (this.transitionCarePlan.transCoordinateName)
            this.practionerTransfer = false;
        else
            this.practionerTransfer = true;
        //this.intakeClient.firstName = event.id;
        //this.intakeClient.dob = event.dob
    }

    protected selectedInstallationChangedreciever(event: any): void {
        
        // this.transitionCarePlan.recTransCoordinateName = event.firstName;
        this.transitionCarePlan.recTransCoordinateREPName = event.firstName;

        this.transitionCarePlan.recTransCoordinateName = event.id;
        if (this.transitionCarePlan.recTransCoordinateName)
            this.practionerreciever = false;
        else
            this.practionerreciever = true;
        //this.intakeClient.firstName = event.id;
        //this.intakeClient.dob = event.dob
    }

    protected clearedInstallationprac(event: any): void {
        this.transitionCarePlan.transCoordinateName = null;
        this.clienttrainername = null;
    }

    protected clearedInstallationreciever(event: any): void {
        this.transitionCarePlan.recTransCoordinateName = null;
        this.clienttrainernamerec = null;
    }

    protected blurInstallation1prac(event: any): void {

    }

    protected blurInstallationreciever(event: any): void {

    }

    dateChange(event) {

        if (this.transitionCarePlan.dtTransition) {

            var timestamp = Date.parse(this.transitionCarePlan.dtTransition.toString());

            if (isNaN(timestamp) == true || this.transitionCarePlan.dtTransition == undefined || this.transitionCarePlan.dtTransition.toString() == 'Invalid Date' ) {
                this._assigndate = true;
                return false;
            }
            else {

                this._assigndate = false;
            }
        }
        else {
            this._assigndate = false;

        }
    }

    transitiondateChange(event) {

        this._InvalidDob = false;
        if (this.transitionCarePlan.lstDtOfService) {

            var timestamp = Date.parse(this.transitionCarePlan.lstDtOfService.toString());

            if (isNaN(timestamp) == true || this.transitionCarePlan.lstDtOfService == undefined || this.transitionCarePlan.lstDtOfService.toString() == 'Invalid Date') {
                this._transitiondate = true;
                return false;
            }
            else {

                this._transitiondate = false;
            }
        }
        else {
            this._transitiondate = false;

        }
    }

    backtolist() {
        this.setcanDeactivate(true);
        this.message.confirm('Are you sure you want to discard the changes?', ' ',
            (isConfirmed) => {
                if (isConfirmed) {
                    this._router.navigate(['app/main/transitionCarePlans/transitionCarePlans']);
                }
            }
        );
    }

    adddtransititonacknowledgement(event?: CreateOrEditAcknowledgementDto[]): void {

        //this._isOpenAcknowledge = true;             
        
        if (event) {
            this.intakeAcknowledgementList = [];
            this.intakeAcknowledgementList = event;

            for (let i = 0; i < this.intakeAcknowledgementList.length; i++) {
                if (this.intakeAcknowledgementList[i].entityId == null || this.intakeAcknowledgementList[i].entityId == 0) {
                    this.intakeAcknowledgementList[i].entityId = this.transitionCarePlan.id;
                    this.intakeAcknowledgementList[i].formId = AppConsts.transitionCarePlanFormId;
                }
                if (this.intakeAcknowledgementList[i].acknowledgementFlag == true) {
                    this.transitionCarePlan.acknowledgementInfo = this.intakeAcknowledgementList;
                    if (this.transitionCarePlan.tccpTransitioninfo) {
                        for (let _d of this.transitionCarePlan.tccpTransitioninfo) {

                            _d.selectLevel = JSON.stringify(_d.selectLevel);
                        }
                    }

                    this.saveIntoDB('Submitted');
                }
            }

        }
    }

    addclientsignature(event: any) {

        if (event == false) {
            this.blockedDocument = false;
            return false;
        }
        else {
            this.blockedDocument = true;
            if (event) {
                this.transitionCarePlan.clientSignature = event[0].clientSignature;
                this.CliSign = this.transitionCarePlan.clientSignature;

                if (this.transitionCarePlan.clientSignature) {
                    this.today = new Date();
                    this.transitionCarePlan.signatureFlag = true;
                    this.transitionCarePlan.clientSignatureDate = moment(this.today)
                    this.CliSignFlag = true;
                }
                else {
                    this.transitionCarePlan.signatureFlag = false;
                    this.transitionCarePlan.clientSignatureDate = null;
                    this.CliSignFlag = false;
                }
            }

            if (this.CliSignFlag) {
                this.transitionCarePlan.transitionstatus = 'Submitted';
                this.transitionCarePlan = this.setIdnull(this.transitionCarePlan);
                this.saveIntoDB(this.transitionCarePlan.transitionstatus);
            }

        }
    }

    intakeredirect(event: boolean) {

        if (event) {
            this.setcanDeactivate(true);
            this.close();
        }
    }

    ParticipationPlanChange(event) {
          
        this.selectedItemValue = this.transitionCarePlan.clientParticipation[0];
    }

    practionerValidation() {

        if (this.transitionCarePlan.transCoordinateName)
            this.practionerTransfer = false;
        else
            this.practionerTransfer = true;

        if (this.transitionCarePlan.recTransCoordinateName)
            this.practionerreciever = false;
        else
            this.practionerreciever = true;
    }

    addCommonMedication(event?: CreateOrEditMedicationDto[]): void {
        
        this.medicationlist = [];
        this.medicationlist = event;

      //  if(this.transitionCarePlan.medicationInfo != null)
        this.transitionCarePlan.medicationInfo = this.medicationlist;
        //    this.commonProviderModal.show(this.treatmentPlan.clientId);
    }

    addCommonProviderlist(event?: CreateOrEditClientproviderDto[]): void {
        
        this.clientproviderlist = [];
        this.clientproviderlist = event;
      //  if (this.transitionCarePlan.providersinfo != null)
        this.transitionCarePlan.providersinfo = this.clientproviderlist;
        //    this.commonProviderModal.show(this.treatmentPlan.clientId);
    }

    public loadStimulScripts() {
        // if (!this.stiScrpLoaded) {
        
        this.loadScript("assets/stimulsoft/stimulsoft.viewer.pack.js");
        this.loadScript("assets/stimulsoft/stimulsoft.reports.pack.js");
        this.loadScript("assets/stimulsoft/stimulsoft.reports.maps.pack.js");
        //this.stiScrpLoaded = true;
        // }
    }

    private loadScript(url: string) {
        
        let node = document.createElement('script');
        node.src = url;
        node.type = 'text/javascript';
        node.async = true;
        node.charset = 'utf-8';
        document.getElementsByTagName('head')[0].appendChild(node);
    }
}
