import { Component, ViewChild, Injector, Output, EventEmitter } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { finalize } from 'rxjs/operators';
import { TCCPTransitionsServiceProxy, CreateOrEditTCCPTransitionDto, CreateOrEditProgramEnrollmentDto, TransitionCarePlansServiceProxy, ClientsServiceProxy, CommonServiceProxy, CreateOrEditTransitionCarePlanDto, CreateOrEditClientDto, CreateOrEditTCCPTransitionDtoProgram, LableValue, NameValueDto } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
import * as moment from 'moment';
//import { CommonProgramEnrollmentModalComponent } from '@app/shared/common/ClientForm/CommonProgramEnrollment/common-programenrollment-modal.component';
import { isNumber } from 'util';
import { SelectItem } from 'primeng/api';


@Component({
    selector: 'createOrEditTCCPTransitionModal',
    templateUrl: './create-or-edit-tccpTransition-modal.component.html'
})
export class CreateOrEditTCCPTransitionModalComponent extends AppComponentBase {

    @ViewChild('createOrEditModal') modal: ModalDirective;
    // @ViewChild('commonProgramEnrollmentModal') commonProgramEnrollmentModal: CommonProgramEnrollmentModalComponent;

    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();
    @Output() TCCPTransitionsave: EventEmitter<any> = new EventEmitter<any>();

   

    active = false;
    saving = false;
    TreatmentSite: any[];
    programs: any[];
    _idx?: number
    _edit: boolean = false;
    Tccptrcold: any;
    originalobj: any;
    LocationSite: any[];
    Selectlevel: any;
    selectedItemValue: any;
    _transitionerrormsg : boolean = false;

    allPrograms: any[];
   
    programid: any;
    RRPDependent: any[] = [];
    RRPDependentvalues: any;
    clinicDependentvalues: any;

    PrpSubValues: any;
    RRPSubValues: any;
    clinicSubValues: any;
    selectedId: any[];
    treatmentsiteName: any;
    _selectatleastoneprogram: any;
    _tccpMultiselectPermission: boolean = true;
    _showError: boolean = false;
    siteName: string;
    pgmName: string;
    // programenrollmentlistdata: any;
    tccpTransition: CreateOrEditTCCPTransitionDto = new CreateOrEditTCCPTransitionDto();
    assigntccpTransition: CreateOrEditTCCPTransitionDto = new CreateOrEditTCCPTransitionDto();
    //tccpTransitionlist: CreateOrEditTCCPTransitionDto[] = new Array();
    //programenrollmentlistdata: CreateOrEditTCCPTransitionDto[] = new Array();
    //programenrollmentlist: CreateOrEditProgramEnrollmentDto[] = new Array();
    programenrollmentlist: CreateOrEditTCCPTransitionDto[] = new Array();
    levels: NameValueDto[] = new Array();
    transitionCarePlan: CreateOrEditTransitionCarePlanDto = new CreateOrEditTransitionCarePlanDto();
    Client: CreateOrEditClientDto = new CreateOrEditClientDto();
    enrollmentDate: Date;
    blockedDocument: boolean = false;
    getPRPdata: any;
    dressGromm: SelectItem[];

    constructor(
        injector: Injector,
        private _transitionCarePlansServiceProxy: TransitionCarePlansServiceProxy,
        private _clientsServiceProxy: ClientsServiceProxy,
        private _commonServiceProxy: CommonServiceProxy,
        private _tccpTransitionsServiceProxy: TCCPTransitionsServiceProxy
    ) {
        super(injector);
    }

    ngOnInit() {
        
        //if (this.tccpTransition.selectLevel == null) {
        //    this.programenrollmentlist;
        //}
           
      
      
        this._commonServiceProxy.getTreatmentsites().subscribe(result => {

            this.LocationSite = result;
            if (this.LocationSite.length == 1)
                this.tccpTransition.location = this.LocationSite[0].value;
        });

        this._commonServiceProxy.gethealthPrograme().subscribe(result => {

            this.allPrograms = result;
        });

        this._commonServiceProxy.getTreatmentsiteall().subscribe(result => {
            this.TreatmentSite = result;
        });

        this._commonServiceProxy.gethealthcareservice().subscribe(result => {

            this.programs = result;
        });

        // this.getprogramenrollmentlistgrid(event);

    }

    //Report DDL
    //selectedItemLabel(event, propertyName, ngmodelobj, Options) {
    //    
        //if (ngmodelobj)
        //   // this.selectedId = ngmodelobj.split(',').map(Number);
        //    this.selectedId = ngmodelobj;
        

        //var Name = [];

        //if (ngmodelobj != null && ngmodelobj != 'undefined') {
        //    for (var i = 0; i < this.selectedId.length; i++) {
        //        if (Options) { 
        //        var selectedItem = Options.filter(s => s.value == this.selectedId[i]);            
        //        Name.push(selectedItem[0].label);
        //        }
        //    }
        //    this.tccpTransition.selectLevelName = Name.join(",");
        //  //  this.transitionCarePlan.selectLevelREPName = Name.join(",");
        //}
   // }

    //Report DDL
    selectedItemLabel(event, propertyName, ngmodelobj, Options,index) {
          

        this.selectedId = ngmodelobj;
        var Name = [];

        if (ngmodelobj != null && ngmodelobj != 'undefined') {
            for (var i = 0; i < this.selectedId.length; i++) {
                try {
                    var selectedItem = Options.filter(s => s.value == this.selectedId[i]);
                    Name.push(selectedItem[0].label);
                } catch{}

                if (this.programenrollmentlist) {
                    for (let j = 0; j < this.programenrollmentlist.length; j++) {
                        this.pgmName = null;
                        if (this.programenrollmentlist[j].status == true && j == index) {
                            this.pgmName = Name.join(",");
                            this.programenrollmentlist[j][propertyName] = this.pgmName;
                        }
                    }
                }
            }
           // this.prol[propertyName] = Name.join(",");
            

        }
    }


    //Report DDL
    ReportValueOnChange(event, index) {
               
       // this.tccpTransition.locationName = event.currentTarget.selectedOptions[0].innerText;
      //  this.transitionCarePlan.locationREPName = event.currentTarget.selectedOptions[0].innerText;

        for (let i = 0; i < this.programenrollmentlist.length; i++)
        {
            this.siteName = null;
            
            if (this.programenrollmentlist[i].status == true && i==index) {
                this.siteName = event.currentTarget.selectedOptions[0].innerText;
                this.programenrollmentlist[i].locationName = this.siteName
            }
        }
        //this.programenrollmentlist.locationName = event.currentTarget.selectedOptions[0].innerText;
    }


    getPRP(data) {
        
        var adddetails = [];
        this.getPRPdata = data;

        var prp = this.getPRPdata.filter(s => s.program == 1);
        var rrp = this.getPRPdata.filter(s => s.program == 2);
        var clinic = this.getPRPdata.filter(s => s.program == 4);

        if (prp.length > 0) {
            if (this.allPrograms)
                var prpvalues = this.allPrograms.filter(s => s.value == 1);          
            if (prpvalues)
                this.PrpSubValues = prpvalues;
        }

        if (rrp.length > 0) {
            if (this.allPrograms)
                var rrpvalues = this.allPrograms.filter(s => s.value == 12 || s.value == 13 || s.value == 14 || s.value == 15);
            if (rrpvalues)
                this.RRPSubValues = rrpvalues

        }
        if (clinic.length > 0) {
            if (this.allPrograms)
                var clinicvalues = this.allPrograms.filter(s => s.value == 11 || s.value == 16 || s.value == 17 || s.value == 18);
            if (clinicvalues)
                this.clinicSubValues = clinicvalues;
        }

        //if (clinic.length > 0) {
        //    if (this.RRPDependentvalues)
        //        this.RRPDependent = this.RRPDependentvalues.concat(this.clinicDependentvalues);
        //}
        //else
        //    this.RRPDependent = this.RRPDependentvalues;

    }

    show(tccpTransitionId?: number): void {
        this.enrollmentDate = null;

        if (!tccpTransitionId) {
            this.tccpTransition = new CreateOrEditTCCPTransitionDto();
            this.tccpTransition.id = tccpTransitionId;
            this._tccpMultiselectPermission = true;
            this.active = true;
            // this.modal.show();
        } else {
            this._tccpTransitionsServiceProxy.getTCCPTransitionForEdit(tccpTransitionId).subscribe(result => {
                this.tccpTransition = result.tccpTransition;
                this.originalobj = Object.assign({}, this.tccpTransition);

                if (this.tccpTransition.enrollmentDate) {
                    this.enrollmentDate = this.tccpTransition.enrollmentDate.toDate();
                }
                this._tccpMultiselectPermission = false;
                this.active = true;
                //  this.modal.show();
            });
        }
    }

    assigndata(tccpTransitionlst: CreateOrEditTCCPTransitionDto[], status: string) {
          
        try { this.programenrollmentlist = tccpTransitionlst; } catch{}
        //for (let i = 0; i < tccpTransitionlst.length; i++) {
        //      
        //    if (tccpTransitionlst[i].program == 1) {
        //        tccpTransitionlst[i].level = this.PrpSubValues;
        //    }
        //    if (tccpTransitionlst[i].program == 2) {
        //        tccpTransitionlst[i].level = this.RRPSubValues;
        //    }
        //    if (tccpTransitionlst[i].program == 4) {
        //        tccpTransitionlst[i].level = this.clinicSubValues;
        //    }
        //}
        
        for (let data of tccpTransitionlst) {
            let tccp = new CreateOrEditTCCPTransitionDto();            
            data.selectLevelList = JSON.parse(data.selectLevel);         
           
        }

       

        if (status == "Submitted" || status == "Acknowledged")
            this._tccpMultiselectPermission = true;
        else
            this._tccpMultiselectPermission = false;
        //this.tccpTransition.location
    }



    showError(errorVariable: boolean) {
        this._showError = errorVariable;
        

    }

    edittccpTransition(result?: CreateOrEditTCCPTransitionDto, idx?: number): void {

        this._idx = idx;
        this._edit = true;
        this.Tccptrcold = null;
        this.originalobj = Object.assign({}, result);
        this.Tccptrcold = Object.assign({}, result);
        this.tccpTransition = result;
        this.tccpTransition.id = idx;
        this.active = true;
        // this.modal.show();
    }

    settreatmentsite(pid: string): any {
        if (this.TreatmentSite)
            return this.TreatmentSite.filter(x => x.value == pid);
    }

    setprograms(pid: number): any {
        //if (pid == 1) {
        //    this._tccpMultiselectPermission = true;
        //}
       
        if (this.programs)
            return this.programs.filter(x => x.healthCareService.id == pid);
    }

    getprogramenrollmentlistgrid(data) {
        
        this.transitionCarePlan.clientID = data;
        this._tccpMultiselectPermission = false;
        this._transitionCarePlansServiceProxy.getTransitionCarePlanclient(this.transitionCarePlan.clientID)
            .pipe(finalize(() => { this.blockedDocument = false; }))
            .subscribe(result => {
                
                this.Client = result.client;
                for (let tccp of result.client.programEnrollmentInfo) {
                    let tccpTransition = new CreateOrEditTCCPTransitionDto();

                    this.tccpTransition.level = [];

                    this.tccpTransition.enrollmentDate = tccp.enrollmentDate;
                    this.tccpTransition.program = !isNumber(tccp.program) ? 0 : Number(tccp.program);

                    
                    this.tccpTransition.treatmentsite = tccp.treatmentSite;
                    this.tccpTransition.treatmentSiteName = tccp.treatmentSiteName;

                    if (this.tccpTransition.program == 1) {
                        var prp = this.getPRPdata.filter(s => s.program == 1);                       
                        if (prp.length > 0) {
                            if (this.allPrograms)
                                this.PrpSubValues = this.allPrograms.filter(s => s.value == 1);
                            if (this.PrpSubValues)
                                this.tccpTransition.level = this.PrpSubValues;
                        }
                    }

                    if (this.tccpTransition.program == 2) {
                        var rrp = this.getPRPdata.filter(s => s.program == 2);
                        if (rrp.length > 0) {
                            if (this.allPrograms)
                                this.RRPSubValues = this.allPrograms.filter(s => s.value == 12 || s.value == 13 || s.value == 14 || s.value == 15);
                            if (this.RRPSubValues)
                                this.tccpTransition.level = this.RRPSubValues;                                                      
                        }
                    }

                    if (this.tccpTransition.program == 4) {
                        var clinic = this.getPRPdata.filter(s => s.program == 4);
                        if (clinic.length > 0) {
                            if (this.allPrograms)
                                this.clinicSubValues = this.allPrograms.filter(s => s.value == 11 || s.value == 16 || s.value == 17 || s.value == 18);
                            if (this.clinicSubValues)
                                this.tccpTransition.level = this.clinicSubValues;                             
                        }
                    }



                    tccpTransition.init(this.tccpTransition);
                    this.programenrollmentlist.push(tccpTransition);
                    var treatmentsite = this.programenrollmentlist.filter(s => s.treatmentsite);
                }

              
                this.transitionCarePlan.legacyID = this.Client.legacyID;
            });
    }

    SetCheckedProgram(event) {
        
        var data = this.programenrollmentlist;
        var checkStatus = false;
        this._showError = false;

        //if (event.target.checked == true) {

        //    this._transitionerrormsg = false;
        //}
        //else {
        //    this._transitionerrormsg = true;;
        //}

       
      
    }

    save(): void {
        

        this.saving = true;
        if (this._edit)
            this.programenrollmentlist.splice(this._idx, 1);
        this._tccpMultiselectPermission = false;
        this._showError = false;
        //for (let data of this.programenrollmentlist) {
        //    let tccptransition = new CreateOrEditTCCPTransitionDto();
        //    tccptransition.selectLevel = JSON.stringify(this.tccpTransition.selectLevel);
        //    tccptransition.location = this.tccpTransition.location;
        //    tccptransition.init(this.tccpTransition);
        //    this.programenrollmentlist.push(tccptransition);
        //    this.TCCPTransitionsave.emit(this.programenrollmentlist);
        //}
        
        var proenroll = this.programenrollmentlist;
        for (let _d of proenroll) {
                if (_d.status) {
                    if (_d.selectLevelList) {
                        _d.selectLevel = JSON.stringify(_d.selectLevelList);
                }
                else {``
                    _d.selectLevel = null;
                }
            }  
        }

       
        this.TCCPTransitionsave.emit(proenroll);
        this._edit = false;

    }

    close(): void {

        this.active = false;
        //  this.modal.hide();
    }

    clearPrograms() {
        this.programenrollmentlist = [];
    }
}

